import React, { Fragment } from 'react';
import { ReferenceArrayField, Datagrid, FunctionField, FormDataConsumer, TextInput } from 'react-admin';

import PaymentQuickCreateButton from './PaymentQuickCreateButton';

const PaymentReferenceInput = props => {
  const formatPayment = payment => {
    const date = payment.date instanceof Date ? payment.date : new Date(payment.date);
    return `${date.toLocaleDateString()} - ${payment.amount}€`;
  }
  return (
    <Fragment>
      <FormDataConsumer>
        {({formData, dispatch, ...rest}) => {
          return (
            <ReferenceArrayField source="payments" reference="payments" {...props}>
              <Datagrid>
                <FunctionField label="Paiements" render={formatPayment} />
              </Datagrid>
            </ReferenceArrayField>
          )
        }}
      </FormDataConsumer>
      <TextInput source="payments" type="hidden" label=""/>
      <PaymentQuickCreateButton {...props} label="Ajouter un paiement"/>
    </Fragment>
  );
}

export default PaymentReferenceInput;
