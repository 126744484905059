import React from 'react';
import { parse } from 'query-string';
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
  ReferenceInput,
  ReferenceArrayInput,
  SelectInput,
  SelectArrayInput
} from 'react-admin';

const OptionValueTitle = ({record}) => {
  return <span>{record ? record.name : 'Nouvelle option'}</span>;
};

const required = (message = 'Obligatoire') =>
  value => value ? undefined : message;

export const OptionValueCreate = (props) => {
  const { option: option_string } = parse(props.location.search);
  const option = option_string ? parseInt(option_string.match(/\d+/)[0], 10) : '';
  const redirect = (basePath, id, data) => `/options/%2Foptions%2F${option}/show/1`;

  return (
    <Create title={<OptionValueTitle/>} {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput
          source="option"
          reference="options"
          allowEmpty
          validate={required()}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="name" validate={required()}/>
        <NumberInput source="price"/>
        <NumberInput source="discount"/>
        <BooleanInput source="isActive"/>
        <NumberInput source="addHour"/>
        <BooleanInput source="addHourBefore"/>
        <BooleanInput source="addHourNightGlued"/>
        <ReferenceArrayInput label="Chambres concernées" source="rooms" reference="rooms" allowEmpty>
          <SelectArrayInput optionText="name"/>
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
}

export const OptionValueEdit = (props) => {
  const redirect = (basePath, id, data) => `/options/%2Foptions%2F${data.option.match(/\d+/)[0]}/show/1`;

  return (
    <Edit title={<OptionValueTitle/>} {...props}>
      <SimpleForm redirect={redirect}>
        <TextInput source="name" validate={required()}/>
        <NumberInput source="price"/>
        <NumberInput source="discount"/>
        <BooleanInput source="isActive"/>
        <NumberInput source="addHour"/>
        <BooleanInput source="addHourBefore"/>
        <BooleanInput source="addHourNightGlued"/>
        <ReferenceArrayInput label="Chambres concernées" source="rooms" reference="rooms" allowEmpty>
          <SelectArrayInput optionText="name"/>
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
}
