import React, { Component, Fragment } from 'react';
import {hydraClient, fetchHydra as baseFetchHydra} from '@api-platform/admin';
import clubDocumentationParser from "../documentationParser";
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Done from '@material-ui/icons/Done';
import { showNotification, UPDATE } from 'react-admin';
import { push } from 'react-router-redux';

class BookingCameButton extends Component {
  state = {
    api: null
  };

  componentDidMount() {
    clubDocumentationParser(process.env.REACT_APP_API_ENTRYPOINT).then(({api}) => {
      this.setState({api});
    }).catch((e) => {
      console.log(e);
    });
  }

  handleClick = (e) => {
    const { push, record, showNotification } = this.props;

    const fetchHeaders = {
      'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
      'X-HOTEL': process.env.REACT_APP_HOTEL
    };
    const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
      ...options,
      headers: new Headers(fetchHeaders),
    });
    const dataProvider = hydraClient(this.state.api, fetchHydra);
    return dataProvider(UPDATE, 'bookings', {
      id: record.id,
      data: {
        came: true
      }
    })
      .then(() => {
        showNotification('La venue est validée');
        push('/bookings');
      })
      .catch((e) => {
        showNotification('Erreur : la venue ne peut être validée', 'warning')
      });
  }

  render() {
    if (!this.props.record.came)
      return <Button label="Venu" color="secondary" onClick={this.handleClick}>
        <Done></Done>
        &nbsp;Venu
      </Button>;

    return <Fragment></Fragment>;
  }
}

export default connect(null, {
  showNotification,
  push,
})(BookingCameButton);
