import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {change, submit, isSubmitting} from 'redux-form';
import {
  fetchEnd,
  fetchStart,
  required,
  showNotification,
  crudGetMatching,
  SaveButton,
  SimpleForm,
  TextInput,
  DateTimeInput,
  CREATE,
  refreshView
} from 'react-admin';
import Button from '@material-ui/core/Button';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {hydraClient, fetchHydra as baseFetchHydra} from '@api-platform/admin';
import clubDocumentationParser from "../documentationParser";

class RoomQuickBookingButton extends Component {
  state = {
    error: false,
    showDialog: false,
    api: null
  };

  componentDidMount() {
    clubDocumentationParser(process.env.REACT_APP_API_ENTRYPOINT).then(({api}) => {
      this.setState({api});
    }).catch((e) => {
      console.log(e);
    });
  }

  handleClick = () => {
    this.setState({showDialog: true});
  };

  handleCloseClick = () => {
    this.setState({showDialog: false});
  };

  handleSaveClick = () => {
    const {submit} = this.props;
    submit('blocking-quick-create');
  };

  handleSubmit = values => {
    const {
      fetchStart,
      fetchEnd,
      showNotification,
      record
    } = this.props;

    fetchStart();

    const fetchHeaders = {
      'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
      'X-HOTEL': process.env.REACT_APP_HOTEL
    };
    const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
      ...options,
      headers: new Headers(fetchHeaders),
    });
    const dataProvider = hydraClient(this.state.api, fetchHydra);

    values.room = record.id

    dataProvider(CREATE, 'room_blockings', {data: values})
      .then(() => {
        this.setState({showDialog: false});
      })
      .catch(error => {
        showNotification(error.message, 'error');
      })
      .finally(() => {
        this.props.refreshView();
        fetchEnd();
      });
  };

  render() {
    const {showDialog} = this.state;
    const {isSubmitting, label} = this.props;

    return (
      <Fragment>
        <Button onClick={this.handleClick} label={label} style={{marginTop: '15px'}}>
          <IconContentAdd></IconContentAdd>
          &nbsp; Ajouter un créneau
        </Button>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="Ajouter un créneau bloqué"
        >
          <DialogTitle>Ajouter un créneau bloqué</DialogTitle>
          <DialogContent>
            <SimpleForm
              form="blocking-quick-create"
              resource="room_blockings"
              onSubmit={this.handleSubmit}
              toolbar={null}
            >
              <TextInput source="reason" />
              <DateTimeInput source="date" validate={required()}  />
              <DateTimeInput source="dateEnd" validate={required()} />
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <SaveButton
              saving={isSubmitting}
              onClick={this.handleSaveClick}
            />
            <Button
              label="ra.action.cancel"
              onClick={this.handleCloseClick}
            >
              <IconCancel/>
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting('blocking-quick-create')(state)
});

const mapDispatchToProps = {
  change,
  crudGetMatching,
  fetchEnd,
  fetchStart,
  showNotification,
  submit,
  refreshView
};

export default connect(mapStateToProps, mapDispatchToProps)(
  RoomQuickBookingButton
);
