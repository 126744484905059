import React from 'react';
import {Link} from 'react-router-dom';
import CardActions from '@material-ui/core/CardActions';
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
  Show,
  List,
  TabbedShowLayout,
  Tab,
  Datagrid,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
  BooleanField,
  ChipField,
  NumberField,
  ShowButton,
  EditButton,
  ListButton,
  DeleteButton,
  RefreshButton,
  ReferenceInput,
  SelectInput,
  Button,
  ReferenceField,
  ReferenceManyField
} from 'react-admin';

const MyChip = ({record, source, ...props}) => (record && (record[source] !== undefined) ?
  <ChipField {...{record, source, ...props}}/>:null)

export const OptionList = (props) => (
  <List {...props} title="Liste des options">
    <Datagrid rowClick="show">
      <TextField source="name"/>
      <BooleanField source="multipleChoices"/>
      <ReferenceField source="room" reference="rooms" allowEmpty={true}>
        <MyChip source="name" />
      </ReferenceField>
      <BooleanField source="isActive"/>
      <ShowButton/>
      <EditButton/>
    </Datagrid>
  </List>
);

const OptionTitle = ({record}) => {
  return <span>{record ? record.name : 'Nouvelle option'}</span>;
};

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

const OptionShowActions = ({basePath, data, resource}) => (
  <CardActions style={cardActionStyle}>
    <EditButton basePath={basePath} record={data}/>
    <ListButton basePath={basePath}/>
    <DeleteButton basePath={basePath} record={data} resource={resource}/>
    <RefreshButton record={data}/>
  </CardActions>
);

const AddValueButton = ({classes, record}) => {
  const styles = {
    marginTop: '2em',
    textTransform: 'none'
  }
  return (
    <Button
      style={styles}
      variant="raised"
      component={Link}
      to={`/option_values/create?option=${record.id}`}
    >
      <span>Ajouter</span>
    </Button>
  );
}

export const OptionShow = (props) => {
  return (
    <Show title={<OptionTitle/>} actions={<OptionShowActions/>} {...props}>
      <TabbedShowLayout>
        <Tab label="L'option">
          <TextField source="name"/>
          <NumberField source="discount"/>
          <BooleanField source="multipleChoices"/>
          <MyChip source="room"/>
        </Tab>
        <Tab label="Les valeurs">
          <ReferenceManyField
            addLabel={false}
            reference="option_values"
            target="option"
            perPage={200}
          >
            <Datagrid>
              <TextField source="name"/>
              <NumberField source="price"/>
              <NumberField source="discount"/>
              <BooleanField source="isActive"/>
              <ReferenceArrayField source="rooms" reference="rooms" {...props}>
                <SingleFieldList>
                  <MyChip source="name" key="name"/>
                </SingleFieldList>
              </ReferenceArrayField>
              <EditButton/>
              <DeleteButton basePath={`${props.location.pathname}`}/>
            </Datagrid>
          </ReferenceManyField>
          <AddValueButton/>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

const required = (message = 'Obligatoire') =>
  value => value ? undefined : message;

const OptionCreateActions = ({basePath, data, resource}) => (
  <CardActions style={cardActionStyle}>
    <ListButton basePath={basePath}/>
  </CardActions>
);

export const OptionCreate = (props) => (
  <Create actions={<OptionCreateActions/>} title={<OptionTitle/>} {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()}/>
      <TextInput source="description" />
      <BooleanInput source="multipleChoices"/>
      <BooleanInput source="isActive"/>
      <NumberInput source="discount"/>
      <ReferenceInput label="Chambre associée" source="room" reference="rooms" allowEmpty>
        <SelectInput optionText="name"/>
      </ReferenceInput>
      <small>L'option sera dispo uniquement pour cette chambre</small>
    </SimpleForm>
  </Create>
);

const OptionEditActions = ({basePath, data, resource}) => (
  <CardActions style={cardActionStyle}>
    <ShowButton basePath={basePath} record={data}/>
    <ListButton basePath={basePath}/>
    <DeleteButton basePath={basePath} record={data} resource={resource}/>
    <RefreshButton record={data}/>
  </CardActions>
);

export const OptionEdit = (props) => (
  <Edit actions={<OptionEditActions/>} title={<OptionTitle/>} {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()}/>
      <TextInput source="description" />
      <BooleanInput source="multipleChoices"/>
      <BooleanInput source="isActive"/>
      <NumberInput source="discount"/>
      <ReferenceInput label="Chambre associée" source="room" reference="rooms" allowEmpty>
        <SelectInput optionText="name"/>
      </ReferenceInput>
      <small>L'option permettra d'ajouter la chambre comme complément (pour un jaccuzi par exemple)</small>
    </SimpleForm>
  </Edit>
);
