import React, {Component} from 'react';
import {connect} from 'react-redux';
import {fetchEnd, fetchStart, showNotification} from 'react-admin';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import clubDocumentationParser from "../documentationParser";

const styles = theme => {
  return ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing.unit * 2,
      color: theme.palette.text.primary,
    },
    textField: {
      marginLeft: theme.spacing.unit * 1,
      marginRight: theme.spacing.unit * 1,
      width: 200,
    },
    list: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
  });
}

class StatsTable extends Component {
  _isMounted = false;
  state = {
    data: null,
    api: null,
    isLoading: true,
    dateStart: (new Date()).toISOString().slice(0, 10),
    dateEnd: (new Date()).toISOString().slice(0, 10)
  };

  componentDidMount() {
    this._isMounted = true;
    clubDocumentationParser().then(({api}) => {
      if (this._isMounted) {
        this.setState({api});
        this.loadData({dateEnd: this.state.dateEnd, dateStart: this.state.dateEnd});
      }
    }).catch((e) => {
      console.log(e);
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeDateStart(event) {
    this.loadData({dateEnd: this.state.dateEnd, dateStart: event.target.value});
  }

  changeDateEnd(event) {
    this.loadData({dateStart: this.state.dateStart, dateEnd: event.target.value});
  }

  loadData(dates) {
    const {
      fetchStart,
      fetchEnd
    } = this.props;
    const {
      entrypoint
    } = this.state.api;
    this.setState({isLoading: true, dateStart: dates.dateStart, dateEnd: dates.dateEnd});

    fetchStart();
    const fetchHeaders = {
      'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
      'X-HOTEL': process.env.REACT_APP_HOTEL
    };
    const entrypointUrl = new URL(entrypoint, window.location.href);
    const stats_uri = new URL(`/bookings/stats/1?date_start=${dates.dateStart}&date_end=${dates.dateEnd}`, entrypointUrl);

    fetch(stats_uri.toString(), {
      headers: new Headers(fetchHeaders)
    })
      .catch(error => {
        showNotification(error.message, 'error');
      })
      .then(response => response.json())
      .then((data) => {
        this.setState({isLoading: false, data})
      })
      .finally(() => fetchEnd())
  }

  render() {
    const {classes} = this.props;
    const {isLoading, dateStart, dateEnd, data} = this.state;

    const periodChooser = (
      <Paper className={classes.paper}>
        <TextField
          label="Début de période"
          type="date"
          defaultValue={dateStart}
          className={classes.textField}
          onChange={this.changeDateStart.bind(this)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <strong>au</strong>
        <TextField
          label="Fin de période"
          type="date"
          defaultValue={dateEnd}
          className={classes.textField}
          onChange={this.changeDateEnd.bind(this)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Paper>
    );

    let stats = null;
    if (isLoading) {
      stats = (
        <Grid item xs={12}>
          <LinearProgress style={{margin: '20px', width: '200px'}}/>
        </Grid>
      );
    } else {
      const i18 = {
        shop: 'Espèces',
        agency: 'Agence',
        online: 'En ligne (manuel)',
        Stripe: 'En ligne (Stripe)',
        card: 'Carte'
      };
      let total = 0, totalOptions = 0, totalSources = 0, totalRooms = 0;
      stats = (
        <>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <h4>Statistiques basées sur les réservations encaissées entre
                le {(new Date(data.dateStart.date)).toLocaleString()} et
                le {(new Date(data.dateEnd.date)).toLocaleString()}</h4>
            </Paper>
          </Grid>
          <Grid item sm={2} xs={12}>
            <Paper className={classes.paper}>
              <h4>Encaissements</h4>
              <List className={classes.list}>
                {
                  data.payments.map(item => {
                    total += parseFloat(item.sum);
                    return (
                      <ListItem divider key={item.mode}>
                        <ListItemText primary={i18[item.mode] ? i18[item.mode] : item.mode} secondary={`${item.sum}€`}/>
                      </ListItem>
                    )
                  })
                }
                <ListItem key={"sum"}>
                  <ListItemText primary={"Total"} secondary={`${total}€`}/>
                </ListItem>
              </List>
            </Paper>
          </Grid>
          <Grid item sm={3} xs={12}>
            <Paper className={classes.paper}>
              <h4>Options vendues</h4>
              <List className={classes.list}>
                {
                  data.options.map(item => {
                    totalOptions += parseFloat(item.value);
                    return (
                      <ListItem divider key={item.desc}>
                        <ListItemText primary={item.desc} secondary={`${item.value}€`}/>
                      </ListItem>
                    )
                  })
                }
                <ListItem key={"sum"}>
                  <ListItemText primary={"Total"} secondary={`${totalOptions}€`}/>
                </ListItem>
              </List>
            </Paper>
          </Grid>
          <Grid item sm={3} xs={12}>
            <Paper className={classes.paper}>
              <h4>Chambres vendues</h4>
              <List className={classes.list}>
                {
                  data.rooms.map(item => {
                    totalRooms += parseFloat(item.value);
                    return (
                      <ListItem divider key={item.desc}>
                        <ListItemText primary={item.desc} secondary={`${item.value}€`}/>
                      </ListItem>
                    )
                  })
                }
                <ListItem key={"sum"}>
                  <ListItemText primary={"Total"} secondary={`${totalRooms}€`}/>
                </ListItem>
              </List>
            </Paper>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Paper className={classes.paper}>
              <h4>Sources</h4>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nom</TableCell>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>%</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.sources.forEach(item => {
                    if (item.sum) {
                      totalSources += parseFloat(item.sum);
                    }
                  })}
                  {data.sources.map(item => (
                    <TableRow key={item.source}>
                      <TableCell>{item.source ? item.source : 'Inconnue'}</TableCell>
                      <TableCell align="right">{item.total}</TableCell>
                      <TableCell align="right">{item.sum ? item.sum : 0}€</TableCell>
                      <TableCell align="right">{item.sum ? (item.sum * 100 / totalSources).toFixed(2) : 0}%</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={2}/>
                    <TableCell>Total</TableCell>
                    <TableCell align="right">{totalSources}€</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </>
      );
    }

    return (
      <div className={classes.root}>
        <Grid container spacing={32}>
          <Grid item xs={12}>
            {periodChooser}
          </Grid>
          {stats}
        </Grid>
      </div>
    );
  }
}

const StatsListWrapper = (props) => (
  <div>
    <StatsTable {...props} />
  </div>
);

const mapDispatchToProps = {
  fetchEnd,
  fetchStart
};

export const StatsList = connect(null, mapDispatchToProps)(
  withStyles(styles)(StatsListWrapper)
);
