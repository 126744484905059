import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {change, submit, isSubmitting} from 'redux-form';
import {
  fetchEnd,
  fetchStart,
  required,
  showNotification,
  crudGetMatching,
  SaveButton,
  SimpleForm,
  DateInput,
  FormDataConsumer,
  CREATE,
  refreshView
} from 'react-admin';
import Button from '@material-ui/core/Button';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {hydraClient, fetchHydra as baseFetchHydra} from '@api-platform/admin';
import clubDocumentationParser from "../documentationParser";
import BookingHoursInput from "./BookingHoursInput";

class RoomQuickWindowButton extends Component {
  state = {
    error: false,
    showDialog: false,
    api: null
  };

  componentDidMount() {
    clubDocumentationParser(process.env.REACT_APP_API_ENTRYPOINT).then(({api}) => {
      this.setState({api});
    }).catch((e) => {
      console.log(e);
    });
  }

  handleClick = () => {
    this.setState({showDialog: true});
  };

  handleCloseClick = () => {
    this.setState({showDialog: false});
  };

  handleSaveClick = () => {
    const {submit} = this.props;
    submit('window-quick-create');
  };

  handleSubmit = values => {
    const {
      fetchStart,
      fetchEnd,
      showNotification,
    } = this.props;

    // Dispatch an action letting react-admin know a API call is ongoing
    fetchStart();

    const fetchHeaders = {
      'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
      'X-HOTEL': process.env.REACT_APP_HOTEL
    };
    const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
      ...options,
      headers: new Headers(fetchHeaders),
    });
    const dataProvider = hydraClient(this.state.api, fetchHydra);

    dataProvider(CREATE, 'room_windows', {data: values})
      .then(() => {
        this.setState({showDialog: false});
      })
      .catch(error => {
        showNotification(error.message, 'error');
      })
      .finally(() => {
        this.props.refreshView();
        fetchEnd();
      });
  };

  render() {
    const {showDialog} = this.state;
    const {isSubmitting, label, record} = this.props;

    return (
      <Fragment>
        <Button onClick={this.handleClick} label={label} style={{marginTop: '15px'}}>
          <IconContentAdd></IconContentAdd>
          &nbsp; Ajouter un créneau
        </Button>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="Ajouter un créneau autorisé"
        >
          <DialogTitle>Ajouter un créneau autorisé</DialogTitle>
          <DialogContent>
            <SimpleForm
              form="window-quick-create"
              resource="room_windows"
              onSubmit={this.handleSubmit}
              toolbar={null}
            >
              <DateInput source="date" validate={required()} onChange={() => document.getElementById('date').blur()} />
              <DateInput source="dateEnd" validate={required()} onChange={() => document.getElementById('dateEnd').blur()} />
              <FormDataConsumer>
                {({formData, dispatch, ...rest}) => (
                  <Fragment>
                    <BookingHoursInput source="hours" key={`${formData.date}`} room={record.id}
                                       date={formData.date}
                                       main={true}
                                       bypassWindows={true}
                                       index={0}
                                       formName="window-quick-create" {...rest}/>
                  </Fragment>
                )}
              </FormDataConsumer>
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <SaveButton
              saving={isSubmitting}
              onClick={this.handleSaveClick}
            />
            <Button
              label="ra.action.cancel"
              onClick={this.handleCloseClick}
            >
              <IconCancel/>
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting('payment-quick-create')(state)
});

const mapDispatchToProps = {
  change,
  crudGetMatching,
  fetchEnd,
  fetchStart,
  showNotification,
  submit,
  refreshView
};

export default connect(mapStateToProps, mapDispatchToProps)(
  RoomQuickWindowButton
);
