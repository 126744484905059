import React, { Component, Fragment } from 'react';
import {hydraClient, fetchHydra as baseFetchHydra} from '@api-platform/admin';
import clubDocumentationParser from "../documentationParser";
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Refresh from '@material-ui/icons/Refresh';
import { showNotification, UPDATE } from 'react-admin';
import { push } from 'react-router-redux';

class BookingSendNotificationsButton extends Component {
  state = {
    api: null
  };

  componentDidMount() {
    clubDocumentationParser(process.env.REACT_APP_API_ENTRYPOINT).then(({api}) => {
      this.setState({api});
    }).catch((e) => {
      console.log(e);
    });
  }

  handleClick = (e) => {
    const { push, record, showNotification } = this.props;

    const fetchHeaders = {
      'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
      'X-HOTEL': process.env.REACT_APP_HOTEL
    };
    const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
      ...options,
      headers: new Headers(fetchHeaders),
    });
    const dataProvider = hydraClient(this.state.api, fetchHydra);
    return dataProvider(UPDATE, 'bookings', {
      id: record.id,
      data: {
        notificationsSent: false
      }
    })
      .then(() => {
        showNotification('Les notifications vont être renvoyées');
        push('/bookings');
      })
      .catch((e) => {
        showNotification('Erreur : les notifications ne peuvent pas être renvoyées', 'warning')
      });
  }

  render() {
    if (!this.props.record.notificationsSent) {
      return <Fragment></Fragment>;
    }

    return <Button label="Renvoyer les notifications" color="primary" onClick={this.handleClick}>
      <Refresh></Refresh>
      &nbsp;Renvoyer les notifications
    </Button>;
  }
}

export default connect(null, {
  showNotification,
  push,
})(BookingSendNotificationsButton);
