import React from 'react';
import { parse } from 'query-string';
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
  ReferenceInput,
  ReferenceArrayInput,
  SelectInput,
  SelectArrayInput,
  DateInput,
  List,
  Datagrid,
  DateField,
  FunctionField,
  ReferenceField,
  Filter,
  ChipField
} from 'react-admin';

const MyChip = ({record, source, ...props}) => (record && (record[source] !== undefined) ?
  <ChipField {...{record, source, ...props}}/>:null)

const PaymentTitle = ({record}) => {
  return <span>{record ? record.name : 'Nouveau paiement'}</span>;
};

const required = (message = 'Obligatoire') =>
  value => value ? undefined : message;

export const PaymentCreate = (props) => {
  const { option: option_string } = parse(props.location.search);
  const option = option_string ? parseInt(option_string.match(/\d+/)[0], 10) : '';
  const redirect = (basePath, id, data) => `/options/%2Foptions%2F${option}/show/1`;

  return (
    <Create title={<PaymentTitle/>} {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput
          source="option"
          reference="options"
          allowEmpty
          validate={required()}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="name" validate={required()}/>
        <NumberInput source="discount"/>
        <ReferenceArrayInput label="Chambres concernées" source="rooms" reference="rooms" allowEmpty>
          <SelectArrayInput optionText="name"/>
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
}

const i18 = {
  shop: 'Espèces',
  agency: 'Agence',
  online: 'En ligne (manuel)',
  Stripe: 'En ligne (stripe)',
  card: 'Carte'
};
const PaymentFilters = (props) => (
  <Filter {...props}>
    <DateInput label="Date" source="date" alwaysOn />
  </Filter>
);
export const PaymentList = (props) => (
  <List {...props} filter={{ temp: false }} title="Liste des paiements" filters={<PaymentFilters />} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid>
      <DateField source="date" showTime/>
      <ReferenceField source="booking" reference="bookings" allowEmpty={true}>
        <MyChip source="name" />
      </ReferenceField>
      <ReferenceField source="card" reference="cards" allowEmpty={true}>
        <MyChip source="name" />
      </ReferenceField>
      <FunctionField source="amount" render={record => `${record.amount}€`}/>
      <FunctionField source="mode" render={record => `${i18[record.mode] ? i18[record.mode] : record.mode}`}/>
    </Datagrid>
  </List>
);

export const PaymentEdit = (props) => {
  const redirect = (basePath, id, data) => `/options/%2Foptions%2F${data.option.match(/\d+/)[0]}/show/1`;

  return (
    <Edit title={<PaymentTitle/>} {...props}>
      <SimpleForm redirect={redirect}>
        <TextInput source="name" validate={required()}/>
        <BooleanInput source="multipleChoices"/>
        <NumberInput source="discount"/>
        <ReferenceArrayInput label="Chambres concernées" source="rooms" reference="rooms" allowEmpty>
          <SelectArrayInput optionText="name"/>
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
}
