import React from 'react';
import CardActions from '@material-ui/core/CardActions';
import {
  Create,
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  DateInput,
  LongTextInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  NumberInput,
  List,
  Datagrid,
  DateField,
  TextField,
  BooleanField,
  ReferenceField,
  FunctionField,
  ChipField,
  NumberField,
  EditButton,
  ListButton,
  FormDataConsumer,
  Filter,
  Toolbar,
  SaveButton,
  ExportButton,
  CreateButton,
  Pagination,
  required
} from 'react-admin';
import PaymentQuickCreateButton from './PaymentQuickCreateButton';
import BookingOptionsInput from './BookingOptionsInput';
import BookingHoursInput from './BookingHoursInput';
import BookingPaymentField from './BookingPaymentField';
import BookingCameButton from "./BookingCameButton";
import BookingCancelButton from "./BookingCancelButton";
import BookingSendNotificationsButton from "./BookingSendNotificationsButton";
import { withStyles } from '@material-ui/core';
import CardQuickCreateButton from "./CardQuickCreateButton";

const MyChip = ({record, source, ...props}) => (record && (record[source] !== undefined) ?
  <ChipField {...{record, source, ...props}}/>:null)

const BookingFilters = (props) => (
  <Filter {...props}>
    <TextInput label="Code secret" source="secret" alwaysOn />
    <TextInput label="Nom" source="name" alwaysOn />
    <TextInput label="Email" source="email" alwaysOn />
    <DateInput label="Date" source="date" alwaysOn />
    <BooleanInput label="Annulée" source="cancelled" alwaysOn defaultValue={false} />
  </Filter>
);

const BookingPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 300]} {...props} />;

const BookingActions = ({
                       bulkActions,
                       basePath,
                       currentSort,
                       displayedFilters,
                       exporter,
                       filters,
                       filterValues,
                       onUnselectItems,
                       resource,
                       selectedIds,
                       showFilter,
                       total
                     }) => (
  <CardActions>
    {bulkActions && React.cloneElement(bulkActions, {
      basePath,
      filterValues,
      resource,
      selectedIds,
      onUnselectItems,
    })}
    {filters && React.cloneElement(filters, {
      resource,
      showFilter,
      displayedFilters,
      filterValues,
      context: 'button',
    }) }
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      maxResults={1000000}
    />
  </CardActions>
);

export const BookingList = (props) => (
  <List {...props} filter={{ temp: false }} filterDefaultValues={{ cancelled: false }} filters={<BookingFilters />} sort={{ field: 'created', order: 'DESC' }}  bulkActionButtons={false} title="Liste des réservations" pagination={<BookingPagination />} actions={<BookingActions />}>
    <Datagrid rowClick="edit">
      <DateField source="created" showTime/>
      <TextField source="name"/>
      <TextField source="email"/>
      <TextField source="phone"/>
      <TextField source="secret"/>
      <BooleanField source="paid"/>
      <DateField source="date"/>
      <ReferenceField source="room" reference="rooms" allowEmpty={true}>
        <MyChip source="name" />
      </ReferenceField>
      <TextField source="hours"/>
      <TextField source="source"/>
      <FunctionField source="amount" render={record => `${record.amount}€`}/>
      <BooleanField source="came"/>
      <NumberField source="fidelity"/>
      <EditButton/>
      <BookingCameButton/>
    </Datagrid>
  </List>
);

const BookingTitle = ({record}) => {
  return <span>{record.name ? `Réservation de ${record.name}` : 'Nouvelle réservation'}</span>;
};

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

const validateBooking = (values) => {
  const errors = {};
  if (!values.rooms || values.rooms.length === 0) {
    errors.hours = 'Vous devez choisir une chambre et ses horaires'
  } else {
    let noMain = true, noMainHours = true;
    values.rooms.forEach(v => {
      if (v) {
        if (v.main) {
          noMain = false;
        }
        if (v.main && v.dateStartPricing && v.dateEndPricing) {
          noMainHours = false;
        }
      }
    });
    if (noMain) {
      errors.hours = 'Vous devez choisir une chambre et ses horaires'
    }
    if (noMainHours) {
      errors.hours = 'Vous devez choisir au moins une heure'
    }
  }
  return errors;
}

const BookingCreateActions = ({basePath, data, resource}) => (
  <CardActions style={cardActionStyle}>
    <ListButton basePath={basePath}/>
  </CardActions>
);

export const BookingCreate = (props) => (
  <Create actions={<BookingCreateActions/>} title={<BookingTitle/>} {...props}>
    <TabbedForm validate={validateBooking} redirect="edit">
      <FormTab label="Réservation">
        <TextInput source="name" validate={required()}/>
        <TextInput source="email" type="email" validate={required()}/>
        <BooleanInput source="confirmationMail"/>
        <TextInput source="phone" />
        <SelectInput source="source" choices={[
          {id: 'Sur place', name: 'Sur place'},
          {id: 'Web', name: 'Web'},
          {id: 'Chat', name: 'Chat'},
          {id: 'Direct', name: 'Direct'},
          {id: 'Booking', name: 'Booking'},
          {id: 'Expédia', name: 'Expédia'},
          {id: 'Autres', name: 'Autres'}
        ]} validate={required()}/>
        <LongTextInput source="comment"/>
        <TextInput source="promo"/>
        <BooleanInput source="amountManual"/>
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.amountManual &&
            <NumberInput source="amount" label={"Total personnalisé"}/>
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({formData, dispatch, ...rest}) => (
            <BookingPaymentField key={`${formData.date}.${formData.room}.${formData.options}.${formData.payments}.${formData.cards}`} payments={false} cards={false}/>
          )}
        </FormDataConsumer>
      </FormTab>
      <FormTab label="Chambre">
        <ReferenceInput label="Chambre" source="room" reference="rooms" filter={{isOption: false, isActive: true}}
                        validate={required()}>
          <SelectInput optionText="name"/>
        </ReferenceInput>
        <DateInput source="date" validate={required()} onChange={() => document.getElementById('date').blur()} />
        <FormDataConsumer>
          {({formData, dispatch, ...rest}) => (
            <BookingHoursInput source="hours" key={`${formData.date}.${formData.room}`} room={formData.room}
                                 date={formData.date}
                                 main={true} {...rest}/>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({formData, dispatch, ...rest}) => (
              <BookingPaymentField key={`${formData.date}.${JSON.stringify(formData.rooms)}.${formData.options}.${formData.payments}.${formData.cards}`} payments={false} cards={false}/>
            )
          }
        </FormDataConsumer>
      </FormTab>
      <FormTab label="Options">
        <FormDataConsumer>
          {({formData, dispatch, ...rest}) => (
            <BookingOptionsInput key={formData.date} date={formData.date} room={formData.room}/>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({formData, dispatch, ...rest}) => (
            <BookingPaymentField key={`${formData.date}.${formData.room}.${formData.options}.${formData.payments}.${formData.cards}`} payments={false} cards={false}/>
          )}
        </FormDataConsumer>
      </FormTab>
    </TabbedForm>
  </Create>
);

const BookingEditActions = ({basePath, data, resource}) => (
  <CardActions style={cardActionStyle}>
  </CardActions>
);

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const BookingEditToolbar = withStyles(toolbarStyles)(props => (
  <Toolbar {...props}>
    <SaveButton />
    <BookingSendNotificationsButton record={props.record} />
    <BookingCancelButton record={props.record} />
  </Toolbar>
));

export const BookingEdit = (props) => (
  <Edit actions={<BookingEditActions/>} title={<BookingTitle/>} {...props}>
    <TabbedForm validate={validateBooking} toolbar={<BookingEditToolbar/>}>
      <FormTab label="Réservation">
        <DateField source="created"/>
        <TextField source="secret"/>
        <TextInput source="name" validate={required()}/>
        <TextInput source="email" type="email" validate={required()}/>
        <BooleanInput source="confirmationMail"/>
        <BooleanInput source="came"/>
        <BooleanInput source="cancelled"/>
        <TextInput source="phone"/>
        <SelectInput source="source" choices={[
          {id: 'Sur place', name: 'Sur place'},
          {id: 'Web', name: 'Web'},
          {id: 'Chat', name: 'Chat'},
          {id: 'Direct', name: 'Direct'},
          {id: 'Booking', name: 'Booking'},
          {id: 'Expédia', name: 'Expédia'},
          {id: 'Autres', name: 'Autres'}
        ]} validate={required()}/>
        <LongTextInput source="comment"/>
        <FormDataConsumer>
          {({formData, dispatch, ...rest}) => (
            <BookingPaymentField key={`${formData.date}.${JSON.stringify(formData.rooms)}.${formData.options}.${formData.payments}.${formData.cards}`} payments={false} cards={false}/>
          )}
        </FormDataConsumer>
      </FormTab>
      <FormTab label="Chambre">
        <ReferenceInput label="Chambre" source="room" reference="rooms" filter={{isOption: false, isActive: true}} validate={required()}>
          <SelectInput optionText="name"/>
        </ReferenceInput>
        <DateInput source="date" validate={required()} onChange={() => document.getElementById('date').blur()} />
        <FormDataConsumer>
          {({formData, dispatch, ...rest}) => (
            <BookingHoursInput source="hours" key={`${formData.date}.${formData.room}`} room={formData.room}
                                 date={formData.date}
                                 main={true} {...rest}/>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({formData, dispatch, ...rest}) => (
            <BookingPaymentField key={`${formData.date}.${formData.room}.${formData.options}.${formData.payments}.${formData.cards}`} payments={false} cards={false}/>
          )}
        </FormDataConsumer>
      </FormTab>
      <FormTab label="Options">
        <FormDataConsumer>
          {({formData, dispatch, ...rest}) => (
            <BookingOptionsInput key={formData.date} date={formData.date} room={formData.room}/>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({formData, dispatch, ...rest}) => (
            <BookingPaymentField key={`${formData.date}.${formData.room}.${formData.options}.${formData.payments}.${formData.cards}`} payments={false} cards={false}/>
          )}
        </FormDataConsumer>
      </FormTab>
      <FormTab label="Paiement">
        <TextInput source="promo"/>
        <BooleanInput source="amountManual"/>
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.amountManual &&
            <NumberInput source="amount" label={"Total personnalisé"}/>
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({formData, dispatch, ...rest}) => (
            <BookingPaymentField key={`${formData.date}.${formData.room}.${formData.options}.${formData.payments}.${formData.cards}`}/>
          )}
        </FormDataConsumer>
        <TextInput source="payments" type="hidden" label=""/>
        <TextInput source="cards" type="hidden" label=""/>
        <PaymentQuickCreateButton label="Ajouter un paiement" {...props} />
        <CardQuickCreateButton label="Utiliser une carte" {...props} />
      </FormTab>
    </TabbedForm>
  </Edit>
);
