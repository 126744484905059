import React, {Fragment} from 'react';
import CardActions from '@material-ui/core/CardActions';
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
  ImageInput,
  Show,
  List,
  TabbedShowLayout,
  Tab,
  Datagrid,
  FunctionField,
  TextField,
  BooleanField,
  NumberField,
  ImageField,
  DateField,
  ShowButton,
  EditButton,
  ListButton,
  DeleteButton,
  RefreshButton,
  DateInput,
  FormDataConsumer,
  ReferenceManyField,
} from 'react-admin';
import BookingHoursInput from "./BookingHoursInput";
import RoomQuickBookingButton from "./RoomQuickBookingButton";
import RoomQuickWindowButton from "./RoomQuickWindowButton";

export const RoomList = (props) => (
  <List {...props} title="Liste des chambres">
    <Datagrid rowClick="show">
      <FunctionField source="id" render={record => `${record.id.match(/\d+/)[0]}`}/>
      <TextField source="name"/>
      <NumberField source="firstHour"/>
      <NumberField source="firstHourWeekend"/>
      <BooleanField source="nightAvailable"/>
      <BooleanField source="isOption"/>
      <BooleanField source="isActive"/>
      <BooleanField source="isRestricted"/>
      <ShowButton/>
      <EditButton/>
    </Datagrid>
  </List>
);

const RoomTitle = ({record}) => {
  return <span>{record ? `Chambre "${record.name}"` : 'Nouvelle chambre'}</span>;
};

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

const RoomShowActions = ({basePath, data, resource}) => (
  <CardActions style={cardActionStyle}>
    <EditButton basePath={basePath} record={data}/>
    <ListButton basePath={basePath}/>
    <DeleteButton basePath={basePath} record={data} resource={resource}/>
    <RefreshButton record={data}/>
  </CardActions>
);

export const RoomShow = (props) => {
  return (
    <Show title={<RoomTitle/>} actions={<RoomShowActions/>} {...props}>
      <TabbedShowLayout>
        <Tab label="Planning">
          <SimpleForm toolbar={null}>
            <DateInput source="date" validate={required()} onChange={() => document.getElementById('date').blur()} />
            <FormDataConsumer>
              {({formData, dispatch, ...rest}) => (
                <Fragment>
                  <BookingHoursInput source="hours" key={formData.date} room={props.id} full={true}
                                     date={formData.date}
                                     main={true} readonly={true} {...rest}/>
                </Fragment>
              )}
            </FormDataConsumer>
          </SimpleForm>
        </Tab>
        <Tab label="Bloquage de créneaux">
          <ReferenceManyField
            addLabel={false}
            reference="room_blockings"
            target="room"
          >
            <Datagrid>
              <TextField source="reason"/>
              <DateField source="date" showTime={true}/>
              <DateField source="dateEnd" showTime={true}/>
              <NumberField source="firstHour"/>
              <NumberField source="lastHour"/>
              <BooleanField source="night"/>
              <DeleteButton basePath={`${props.location.pathname}`}/>
            </Datagrid>
          </ReferenceManyField>
          <RoomQuickBookingButton/>
        </Tab>
        <Tab label="Autorisation de créneaux">
          <ReferenceManyField
            addLabel={false}
            reference="room_windows"
            target="room"
          >
            <Datagrid>
              <DateField source="date"/>
              <DateField source="dateEnd"/>
              <NumberField source="firstHour"/>
              <NumberField source="lastHour"/>
              <BooleanField source="night"/>
              <DeleteButton basePath={`${props.location.pathname}`}/>
            </Datagrid>
          </ReferenceManyField>
          <RoomQuickWindowButton/>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

const required = (message = 'Obligatoire') =>
  value => value ? undefined : message;

const RoomCreateActions = ({basePath, data, resource}) => (
  <CardActions style={cardActionStyle}>
    <ListButton basePath={basePath}/>
  </CardActions>
);

export const RoomCreate = (props) => (
  <Create actions={<RoomCreateActions/>} title={<RoomTitle/>} {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()}/>
      <ImageInput source="image" accept="image/*">
        <ImageField source="src" />
      </ImageInput>
      <NumberInput source="priceHour" validate={required()}/>
      <NumberInput source="priceNight"/>
      <NumberInput source="firstHour"/>
      <NumberInput source="firstHourWeekend"/>
      <NumberInput source="lastHour" validate={required()}/>
      <NumberInput source="lastHourWeekend" validate={required()}/>
      <NumberInput source="hourOffset"/>
      <BooleanInput source="nightAvailable"/>
      <BooleanInput source="isOption"/>
      <BooleanInput source="isActive"/>
      <BooleanInput source="isRestricted"/>
    </SimpleForm>
  </Create>
);

const RoomEditActions = ({basePath, data, resource}) => (
  <CardActions style={cardActionStyle}>
    <ShowButton basePath={basePath} record={data}/>
    <ListButton basePath={basePath}/>
    <DeleteButton basePath={basePath} record={data} resource={resource}/>
    <RefreshButton record={data}/>
  </CardActions>
);

export const RoomEdit = (props) => (
  <Edit actions={<RoomEditActions/>} title={<RoomTitle/>} {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()}/>
      <ImageInput source="image" key="image" accept="image/*">
        <ImageField source="src" />
      </ImageInput>
      <NumberInput source="priceHour" validate={required()}/>
      <NumberInput source="priceNight"/>
      <NumberInput source="firstHour"/>
      <NumberInput source="firstHourWeekend"/>
      <NumberInput source="lastHour" validate={required()}/>
      <NumberInput source="lastHourWeekend" validate={required()}/>
      <NumberInput source="hourOffset"/>
      <BooleanInput source="nightAvailable"/>
      <BooleanInput source="isOption"/>
      <BooleanInput source="isActive"/>
      <BooleanInput source="isRestricted"/>
    </SimpleForm>
  </Edit>
);
