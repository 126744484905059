import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {change, submit} from 'redux-form';
import {
  fetchEnd,
  fetchStart,
  showNotification,
  crudGetMatching,
  GET_LIST,
  GET_MANY,
  RadioButtonGroupInput,
  CheckboxGroupInput
} from 'react-admin';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormLabel from '@material-ui/core/FormLabel';
import {hydraClient, fetchHydra as baseFetchHydra} from '@api-platform/admin';
import clubDocumentationParser from "../documentationParser";
import BookingHoursInput from './BookingHoursInput';

class BookingOptionsInput extends Component {
  _isMounted = false;
  _options = null;

  state = {
    error: false,
    options: [],
    api: null,
    isLoading: true
  };

  componentDidMount() {
    this._isMounted = true;
    clubDocumentationParser().then(({api}) => {
      if (this._isMounted) {
        this.setState({isLoading: false, api})
        this.initOptions()
      }
    }).catch((e) => {
      console.log(e);
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  initOptions = values => {
    const {
      fetchStart,
      fetchEnd,
      showNotification
    } = this.props;

    // Dispatch an action letting react-admin know a API call is ongoing
    fetchStart();
    const fetchHeaders = {
      'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
      'X-HOTEL': process.env.REACT_APP_HOTEL
    };
    const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
      ...options,
      headers: new Headers(fetchHeaders),
    });
    const dataProvider = hydraClient(this.state.api, fetchHydra);

    if (!this._options) {
      dataProvider(GET_LIST, 'options', {
        pagination: {page: 1, perPage: 99999},
        sort: {field: 'id', order: 'DESC'}
      })
        .then((response) => {
          response.data.forEach((option, i) => {
            option.values.forEach((value, j) => {
              dataProvider(GET_MANY, 'options', {
                ids: [value]
              }).then(({data}) => {
                if (!data[0].isActive) {
                  data[0].name += ' (inactive)'
                }
                response.data[i].values[j] = data[0]
              })
            })
          })
          return Promise.resolve(response.data);
        })
        .then(data => {
          if (this._isMounted) {
            this.setState({options: data});
          }
          this._options = data;
        })
        .catch(error => {
          showNotification(error.message, 'error');
        })
        .finally(() => {
          // Dispatch an action letting react-admin know a API call has ended
          fetchEnd();
        });
    } else if (this._isMounted) {
      this.setState({options: this._options});
    }
  };

  render() {
    const {options, isLoading} = this.state;

    if (isLoading) {
      return (
        <LinearProgress style={{margin: '20px', width: '200px'}} />
      );
    }

    const optionList = options.map((option) => {
      const optionName = `${option.name}${option.active ? '' : ' (inactive)'}`
      if (option.room) {
        return (
          <div key={option.id}>
            <FormLabel>{optionName}</FormLabel>
            <BookingHoursInput source="hours" key={option.room} room={option.room} date={this.props.date} main={false}/>
          </div>
        );
      }
      const values = option.values.filter(value =>
        value.rooms.length === 0 || (this.props.room && value.rooms.indexOf(this.props.room) !== -1)
      );
      if (!option.multipleChoices) {
        values.unshift({
          id: '',
          name: 'Aucune'
        })
      }
      return option.multipleChoices ?
        <CheckboxGroupInput key={option.id} label={optionName} reference="optionValue" source={`options.${option.id.match(/\d+/)[0]}`} choices={values} /> :
        <RadioButtonGroupInput key={option.id} label={optionName} reference="optionValue" source={`options.${option.id.match(/\d+/)[0]}`} choices={values}/>
    })

    return (
      <Fragment>
        {optionList}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  change,
  crudGetMatching,
  fetchEnd,
  fetchStart,
  showNotification,
  submit
};

export default connect(mapStateToProps, mapDispatchToProps)(
  BookingOptionsInput
);
