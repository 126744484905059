import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {fetchEnd, fetchStart} from 'react-admin';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import Done from '@material-ui/icons/Done';
import { Drawer, withStyles } from '@material-ui/core';
import compose from 'recompose/compose';
import { BookingEdit } from './Bookings'
import clubDocumentationParser from "../documentationParser";

const styles = theme => {
  return ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing.unit * 2,
      color: theme.palette.text.primary,
    },
    drawerContent: {
      width: 700
    },
    chip: {
      fontSize: '0.7em',
      height: '21px',
      marginRight: '4px',
      '& span': {
        padding: '0 5px'
      },
      '& svg': {
        height: '0.5em',
        width: '0.5em'
      }
    }
  });
}

class ReportingTable extends Component {
  _isMounted = false;
  state = {
    data: null,
    api: null,
    isLoading: true,
    editBooking: false,
    date: (new Date()).toISOString().slice(0, 10)
  };

  componentDidMount() {
    this._isMounted = true;
    clubDocumentationParser().then(({api}) => {
      if (this._isMounted) {
        this.setState({api});
        this.loadData();
      }
    }).catch((e) => {
      console.log(e);
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  editBooking(id) {
    this.setState({editBooking: id});
  }

  handleClose() {
    this.setState({editBooking: false});
  }

  changeDate(event) {
    this.setState({isLoading: true, date: event.target.value});
    this.loadData(event.target.value);
  }

  stringToColour(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xFF;
      colour += ('AA' + (value - value % 100).toString(16)).substr(-2);
    }
    return colour;
  }

  loadData(dateParam = null) {
    const {
      fetchStart,
      fetchEnd
    } = this.props;
    fetchStart();

    let {date, api: {entrypoint}} = this.state;
    if (dateParam) {
      date = dateParam
    }
    const dateTomorrow = new Date(date)
    dateTomorrow.setDate(dateTomorrow.getDate() + 1);

    const entrypointUrl = new URL(entrypoint, window.location.href);
    const occupation = new URL(`rooms/occupation_full/${date}/1`, entrypointUrl);
    const occupationTomorrow = new URL(`rooms/occupation_full/${dateTomorrow.toISOString().slice(0, 10)}/1`, entrypointUrl);

    const headers = {
      'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
      'X-HOTEL': process.env.REACT_APP_HOTEL
    };

    return Promise.all([
      fetch(occupation.toString(), {headers})
        .then(response => response.json())
        .then((data) => {
          return Promise.resolve({data})
        }),
      fetch(occupationTomorrow.toString(), {headers})
        .then(response => response.json())
        .then((dataTomorrow) => {
          return Promise.resolve({dataTomorrow})
        })
    ]).then(([{data}, {dataTomorrow}]) => {
      this.setState({isLoading: false, data, dataTomorrow})
      fetchEnd()
    })
  }

  render() {
    const {isLoading, date, data, dataTomorrow, editBooking} = this.state;
    const {classes, fetchStart, fetchEnd, ...props} = this.props;

    if (isLoading) {
      return (
        <LinearProgress style={{margin: '20px', width: '200px'}}/>
      );
    }

    // Variables
    let headers = [], hours = [], footer = [], colHour = null;

    // Headers
    data.forEach(room => {
      if (colHour !== room.room.hourOffset) {
        headers.push(<TableCell key={'offset' + room.room.hourOffset} style={{padding: '5px', position: 'sticky', top: 0, background: '#fff' }}/>);
      }
      colHour = room.room.hourOffset;
      headers.push(<TableCell key={'room' + room.id} style={{padding: '5px', position: 'sticky', top: 0, background: '#fff' }}>{room.room.name}</TableCell>);
    });

    const drawLine = (i, dataSource) => {
      const line = [];
      let colHour = null;
      dataSource.forEach(room => {
        if (colHour !== room.room.hourOffset) {
          const offset = (room.room.hourOffset + '').padStart(2, '0');
          const hour = `${i}h${offset} - ${i === 23 ? 0 : i+1}h${offset}`
          line.push(<TableCell key={room.id + i + offset} style={{backgroundColor: '#f0f0f0',padding: '5px' }}>{hour}</TableCell>);
        }
        colHour = room.room.hourOffset;
        if (room.occupation.hours && room.occupation.hours[i]) {
          const booking = room.occupation.hours[i]
          const color = this.stringToColour(room.id + booking.id + (booking.name ? booking.name : booking.reason));
          line.push(
            <TableCell key={room.id + i} style={{backgroundColor: color, padding: '5px'}} onClick={() => booking.name ? this.editBooking(booking.id) : true}>
              <div style={{ display: 'flex', marginBottom: '7px' }}>
                {booking.came &&
                  <Chip
                    className={classes.chip}
                    label={<Done />}
                    color="secondary"
                  />}
                {booking.source === 'Web' &&
                  <Chip
                    className={classes.chip}
                    label="Web"
                    color="primary"
                  />}
              </div>
              <span>{booking.name ? booking.name : `Bloqué : ${booking.reason}`}</span>
            </TableCell>
          );
        } else {
          line.push(<TableCell key={room.id + i} style={{padding: '5px' }}>-</TableCell>);
        }
      });
      return line
    }

    // Hours
    for (let i = 5; i < 24; ++i) {
      hours.push(drawLine(i, data));
    }
    /*for (let i = 0; i < 10; ++i) {
      hours.push(drawLine(i, dataTomorrow));
    }*/

    // Footer
    colHour = null;
    data.forEach(room => {
      if (colHour !== room.room.hourOffset) {
        footer.push(<TableCell key={'offset' + room.room.hourOffset + 'total'} style={{padding: '5px'}}/>);
      }
      colHour = room.room.hourOffset;
    });

    return (
      <Fragment>
        <div className={classes.root}>
          <Grid container spacing={32}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <TextField
                  label="Résumé du"
                  type="date"
                  defaultValue={date}
                  className={classes.textField}
                  onBlur={this.changeDate.bind(this)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Paper>

              <Paper className={classes.paper} style={{marginTop: '32px'}}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {headers}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {hours.map((line, index) => <TableRow key={index}>{line}</TableRow>)}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      {footer}
                    </TableRow>
                  </TableFooter>
                </Table>
              </Paper>
            </Grid>
          </Grid>
        </div>
        <Drawer
          open={editBooking !== false}
          anchor="right"
          onClose={this.handleClose.bind(this)}
        >
          {editBooking && (
            <BookingEdit className={classes.drawerContent}
                         id={`/bookings/${editBooking}`}
                         onCancel={this.handleClose.bind(this)}
                         {...props}
                         resource="bookings"
            />
          )}
        </Drawer>
      </Fragment>
    );
  }
}

const ReportingListWrapper = (props) => (
  <div>
    <ReportingTable {...props} />
  </div>
);

const mapDispatchToProps = {
  fetchEnd,
  fetchStart
};

export const ReportingList = compose(
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(ReportingListWrapper);
