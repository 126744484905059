import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {change, submit, isSubmitting} from 'redux-form';
import {
  fetchEnd,
  fetchStart,
  required,
  showNotification,
  crudGetMatching,
  Button,
  SaveButton,
  SimpleForm,
  SelectInput,
  NumberInput,
  CREATE,
  GET_LIST,
  REDUX_FORM_NAME
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {hydraClient, fetchHydra as baseFetchHydra} from '@api-platform/admin';
import clubDocumentationParser from "../documentationParser";

class PaymentQuickCreateButton extends Component {
  state = {
    error: false,
    showDialog: false,
    api: null
  };

  componentDidMount() {
    clubDocumentationParser(process.env.REACT_APP_API_ENTRYPOINT).then(({api}) => {
      this.setState({api});
    }).catch((e) => {
      console.log(e);
    });
  }

  handleClick = () => {
    this.setState({showDialog: true});
  };

  handleCloseClick = () => {
    this.setState({showDialog: false});
  };

  handleSaveClick = () => {
    const {submit} = this.props;
    submit('payment-quick-create');
  };

  handleSubmit = values => {
    const {
      change,
      crudGetMatching,
      fetchStart,
      fetchEnd,
      showNotification,
      resource,
      id,
      record
    } = this.props;

    // Dispatch an action letting react-admin know a API call is ongoing
    fetchStart();

    const fetchHeaders = {
      'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
      'X-HOTEL': process.env.REACT_APP_HOTEL
    };
    const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
      ...options,
      headers: new Headers(fetchHeaders),
    });
    const dataProvider = hydraClient(this.state.api, fetchHydra);

    if (resource === 'bookings') {
      values.booking = id;
      values.card = null
    }

    if (resource === 'cards') {
      values.card = record.id;
      values.booking = null
    }

    dataProvider(CREATE, 'payments', {data: values})
      .then(({data}) => {
        if (resource === 'bookings') {
          dataProvider(GET_LIST, 'payments', {
            pagination: {page: 1, perPage: 99999},
            sort: {field: 'id', order: 'DESC'},
            filter: {booking: id}
          }).then(({data}) => {
            change(REDUX_FORM_NAME, 'payments', data.map(payment => payment['@id']));
            this.setState({showDialog: false});
          })
        } else if (resource === 'cards') {
          dataProvider(GET_LIST, 'payments', {
            pagination: {page: 1, perPage: 99999},
            sort: {field: 'id', order: 'DESC'},
            filter: {card: record.id}
          }).then(({data}) => {
            change(REDUX_FORM_NAME, 'payments', data.map(payment => payment['@id']));
            this.setState({showDialog: false});
          })
        } else {
          crudGetMatching(
            'payments',
            `${this.props.resource}@payment`,
            {page: 1, perPage: 25},
            {field: 'id', order: 'DESC'},
            {}
          );
          change(REDUX_FORM_NAME, 'payment', data.id);
          this.setState({showDialog: false});
        }
      })
      .catch(error => {
        showNotification(error.message, 'error');
      })
      .finally(() => {
        fetchEnd();
      });
  };

  render() {
    const {showDialog} = this.state;
    const {isSubmitting, label} = this.props;

    return (
      <Fragment>
        <Button onClick={this.handleClick} label={label}>
          <IconContentAdd/>
        </Button>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="Créer un paiement"
        >
          <DialogTitle>Créer un paiement</DialogTitle>
          <DialogContent>
            <SimpleForm
              form="payment-quick-create"
              resource="payments"
              onSubmit={this.handleSubmit}
              toolbar={null}
            >
              <SelectInput source="mode" label="Mode de paiement" validate={required()} choices={[
                {id: 'shop', name: 'Espèces'},
                {id: 'agency', name: 'Agence'},
                {id: 'online', name: 'En ligne (manuel)'},
                {id: 'card', name: 'Carte'},
              ]}/>
              <NumberInput source="amount" validate={required()}/>
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <SaveButton
              saving={isSubmitting}
              onClick={this.handleSaveClick}
            />
            <Button
              label="ra.action.cancel"
              onClick={this.handleCloseClick}
            >
              <IconCancel/>
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting('payment-quick-create')(state)
});

const mapDispatchToProps = {
  change,
  crudGetMatching,
  fetchEnd,
  fetchStart,
  showNotification,
  submit
};

export default connect(mapStateToProps, mapDispatchToProps)(
  PaymentQuickCreateButton
);
