import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getFormValues} from 'redux-form';
import {
  fetchEnd,
  fetchStart,
  showNotification,
  GET_ONE,
  GET_LIST,
  REDUX_FORM_NAME
} from 'react-admin';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import {
  Event as EventIcon,
  Settings as SettingsIcon,
  Payment as PaymentIcon,
  FormatListBulleted,
  CardGiftcard
} from '@material-ui/icons';
import {hydraClient, fetchHydra as baseFetchHydra} from '@api-platform/admin';
import clubDocumentationParser from "../documentationParser";

class BookingPaymentField extends Component {
  _isMounted = false;
  state = {
    error: false,
    options: [],
    rooms: [],
    cards: [],
    discounts: {},
    api: null,
    isLoading: true
  };

  componentDidMount() {
    this._isMounted = true;
    clubDocumentationParser().then(({api}) => {
      if (this._isMounted) {
        this.setState({api})
        this.initResources().then(() => {
          this.setState({isLoading: false});
        }).catch(error => {
          this.props.showNotification(error.message, 'error');
        })
      }
    }).catch((e) => {
      console.log(e);
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  initResources = () => {
    const {
      fetchStart,
      fetchEnd,
      recordLiveValues
    } = this.props;
    const fetchHeaders = {
      'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
      'X-HOTEL': process.env.REACT_APP_HOTEL
    };
    const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
      ...options,
      headers: new Headers(fetchHeaders),
    });
    const dataProvider = hydraClient(this.state.api, fetchHydra);
    fetchStart();
    const entrypointUrl = new URL(process.env.REACT_APP_API_ENTRYPOINT);
    let toFetch = [];

    toFetch.push(
      new Promise( (resolve, reject) => {
        const bookingInfos = {...recordLiveValues};
        fetch(new URL('/bookings/summary', entrypointUrl).toString(), {
          method: 'PUT',
          headers: new Headers({
            'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-HOTEL': process.env.REACT_APP_HOTEL
          }),
          body: JSON.stringify(bookingInfos)
        })
          .then(response => response.json())
          .then(summary => {
            if (this._isMounted) {
              this.setState({summary});
            }
            resolve()
          })
          .catch(e => this.setState({error: e.message}))
      })
    )

    if (recordLiveValues && recordLiveValues.options) {
      const options_uri = new URL('/options', entrypointUrl);
      toFetch.push(
        new Promise( (resolve, reject) => {
          fetch(options_uri.toString(), {
            headers: new Headers({
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-HOTEL': process.env.REACT_APP_HOTEL
            })
          })
            .then(response => response.json())
            .then(options => {
              if (this._isMounted) {
                this.setState({options});
              }
              resolve()
            })
            .catch(e => reject(e.message))
        })
      )
    }

    toFetch.push(
      new Promise((resolve, reject) => {
        dataProvider(GET_LIST, 'rooms', {
          pagination: {page: 1, perPage: 99999},
          sort: {field: 'id', order: 'DESC'}
        })
          .then(({data}) => {
            if (this._isMounted) {
              this.setState({'rooms': data});
            }
            resolve()
          })
          .catch(e => reject(e.message))
      })
    )

    if (recordLiveValues && recordLiveValues.payments) {
      toFetch.push(
        new Promise((resolve, reject) => {
          Promise.all(
            recordLiveValues.payments.map(id => dataProvider(GET_ONE, 'payments', {id}))
          ).then(responses => ({data: responses.map(({data}) => data)}))
            .then(({data}) => {
              if (this._isMounted) {
                this.setState({'payments': data});
              }
              resolve()
            })
            .catch(e => reject(e.message))

        })
      )
    }

    if (recordLiveValues && recordLiveValues.cards) {
      toFetch.push(
        new Promise((resolve, reject) => {
          Promise.all(
            recordLiveValues.cards.map(id => dataProvider(GET_ONE, 'cards', {id}))
          ).then(responses => ({data: responses.map(({data}) => data)}))
            .then(({data}) => {
              if (this._isMounted) {
                this.setState({'cards': data});
              }
              resolve()
            })
            .catch(e => reject(e.message))

        })
      )
    }

    if (recordLiveValues && recordLiveValues.rooms && recordLiveValues.date) {
      const discounts_uri = new URL('/discounts/check/', entrypointUrl);

      recordLiveValues.rooms.forEach((room) => {
        toFetch.push(
          new Promise((resolve, reject) => {
            fetch(`${discounts_uri.toString() + recordLiveValues.date}/${room.room.match(/\d+/)[0]}`, {
              headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-HOTEL': process.env.REACT_APP_HOTEL
              })
            })
              .then(response => response.json())
              .then(result => {
                if (this._isMounted) {
                  let discounts = this.state.discounts;
                  discounts[room.room.match(/\d+/)[0]] = result
                  this.setState({discounts});
                }
                resolve()
              })
              .catch(e => reject(e.message))
          })
        )
      })
    }

    return Promise.all(toFetch).then(() => {
      fetchEnd()
    });
  };

  render() {
    const {
      recordLiveValues
    } = this.props;
    const {isLoading, summary, options, payments, cards, rooms} = this.state;

    if (isLoading) {
      return (
        <LinearProgress style={{margin: '20px', width: '200px'}}/>
      );
    }

    if (summary.error) {
      return (
        <p>{summary.error}</p>
      );
    }

    const listItems = [];
    let priceLists = [];
    let total = 0, roomMainHourPrice = null, roomMainNightPrice = null;
    if (recordLiveValues && recordLiveValues.rooms) {
      recordLiveValues.rooms.forEach((room, index) => {
        if (!room) {
          return null;
        }
        const roomData = rooms.filter(v => v['@id'] === room.room);
        if (!roomData.length) {
          return null;
        }
        if (room.main) {
          roomMainHourPrice = roomData[0].priceHour
          roomMainNightPrice = roomData[0].priceNight
        }
      })
    }
    summary.forEach(item => {
      if (item.type === 'hours') {
        total += item.count*item.value
        listItems.push(
          <ListItem key={item.type}>
            <Avatar>
              <EventIcon />
            </Avatar>
            <ListItemText primary={`${item.item} - ${item.count} heure${item.count > 1 ? 's' : ''}`} secondary={`${item.count*item.value}€`} />
          </ListItem>
        );
      }
      if (item.type === 'hours_discount') {
        total += item.count*item.value
        listItems.push(
          <ListItem key={item.type}>
            <Avatar>
              <EventIcon />
            </Avatar>
            <ListItemText primary={`${item.desc} - ${item.count} heure${item.count > 1 ? 's' : ''}`} secondary={`${item.count*item.value}€`} />
          </ListItem>
        );
      }
      if (item.type === 'night') {
        total += item.count*item.value
        listItems.push(
          <ListItem key={item.type}>
            <Avatar>
              <EventIcon />
            </Avatar>
              <ListItemText primary={`${item.item} - Nuit`} secondary={`${item.count*item.value}€`} />
          </ListItem>
        );
      }
      if (item.type === 'night_discount') {
        total += item.count*item.value
        listItems.push(
          <ListItem key={item.type}>
            <Avatar>
              <EventIcon />
            </Avatar>
            <ListItemText primary={`${item.desc} - Nuit`} secondary={`${item.count*item.value}€`} />
          </ListItem>
        );
      }
      if (item.type === 'priceLists') {
        priceLists = item.value
      }
    })
    if (recordLiveValues && recordLiveValues.options) {
      options.forEach((option, index) => {
        if (!recordLiveValues.options[option.id] || recordLiveValues.options[option.id].length === 0) {
          return true;
        }
        let desc = '';
        const coef = option.discount === 0 ? 1 : (1-option.discount/100);
        let price = 0;
        option.values.forEach((optionValue) => {
          if (recordLiveValues.options[option.id].indexOf('/option_values/' + optionValue.id) === -1) {
            return true;
          }
          desc += desc.length > 0 ? `, ${optionValue.name}` : optionValue.name;
          price += optionValue.price * coef * (!optionValue.discount || optionValue.discount === 0 ? 1 : (1-optionValue.discount/100));
        });
        listItems.push(
          <ListItem key={option['@id'] + index}>
            <Avatar>
              <SettingsIcon />
            </Avatar>
            <ListItemText primary={`${option.name} : ${desc}`} secondary={`${price}€`} />
          </ListItem>
        );
        total += price;
      });
    }
    const listPayments = [];
    let totalPayments = 0;
    const i18 = {
      shop: 'Espèces',
      agency: 'Agence',
      online: 'En ligne (manuel)',
      Stripe: 'En ligne (stripe)',
      card: 'Carte'
    };
    if (payments) {
      payments.forEach((payment, index) => {
        totalPayments += payment.amount;
        listPayments.push(
          <ListItem key={payment.id+index}>
            <Avatar>
              <PaymentIcon />
            </Avatar>
            <ListItemText primary={`${i18[payment.mode] ? i18[payment.mode] : payment.mode}`} secondary={`${payment.amount}${payment.mode === 'card' ? '' : '€'}`} />
          </ListItem>
        );
      });
    }
    if (cards && roomMainHourPrice) {
      cards.forEach((card, index) => {
        totalPayments += card.amount * roomMainHourPrice + card.night * roomMainNightPrice;
        if (card.amount) {
          listPayments.push(
            <ListItem key={card.id+index}>
              <Avatar>
                <CardGiftcard />
              </Avatar>
              <ListItemText primary={`Carte #${card.card.match(/\d+/)} pour ${card.amount}h`} secondary={`${card.amount * roomMainHourPrice}€`} />
            </ListItem>
          );
        }
        if (card.night) {
          listPayments.push(
            <ListItem key={card.id+index+'night'}>
              <Avatar>
                <CardGiftcard />
              </Avatar>
              <ListItemText primary={`Carte #${card.card.match(/\d+/)} pour la nuit`} secondary={`${card.night * roomMainNightPrice}€`} />
            </ListItem>
          );
        }
      })
    }

    let priceListsUsed = '';
    if (priceLists.length) {
      priceListsUsed = <div style={{maxWidth: '600px', margin: '10px 20px', padding: '15px 0'}}>
        <strong>Grilles tarifaires utilisées</strong>
        <List style={{background: '#f0f0f0', margin: '10px 0', padding: '10px'}}>
          {priceLists.map(priceList =>
            <ListItem key={priceList.id+'pl'}>
              <Avatar>
                <FormatListBulleted />
              </Avatar>
              <ListItemText primary={`${priceList.name}`} secondary={`Crée le ${(new Date(priceList.dateCreation.date)).toLocaleString()}`} />
            </ListItem>)}
        </List>
      </div>;
    }

    let paymentsBlock = '';
    if (typeof this.props.payments === 'undefined' || this.props.payments) {
      paymentsBlock = <div style={{maxWidth: '600px', margin: '10px 0', padding: '15px 0'}}>
        <strong>Paiements</strong>
        <List style={{background: '#f0f0f0', margin: '10px 0', padding: '10px'}}>
          {listPayments}
        </List>
        <strong>Total : {totalPayments}€</strong>
      </div>;
    }

    return (
      <div style={{display: 'flex'}}>
        <div style={{maxWidth: '600px', margin: '10px 20px 10px 0', padding: '15px 0'}}>
          <strong>Récapitulatif du prix</strong>
          <List style={{background: '#f0f0f0', margin: '10px 0', padding: '10px'}}>
            {listItems}
          </List>
          <strong>Total : {total}€</strong>
        </div>
        {priceListsUsed}
        {paymentsBlock}
      </div>
    );
  }
}

export default connect(state => ({
  recordLiveValues: getFormValues(REDUX_FORM_NAME)(state)
}), {
  fetchEnd,
  fetchStart,
  showNotification
})(
  BookingPaymentField
);
