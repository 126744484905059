import React from 'react';
import { Login, LoginForm } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
  main: {  },
  avatar: {
    background: 'url(//lovehotelaparis.fr/uploads/2019/12/logo-1.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    height: 80,
  },
  icon: { display: 'none' },
});

const CustomLoginPage = props => (
  <Login
    loginForm={<LoginForm />}
    backgroundImage="//lovehotelaparis.fr/uploads/2020/02/IMG_05_4.jpg"
    {...props}
  />
);

export default withStyles(styles)(CustomLoginPage);
