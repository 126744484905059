import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {change, submit, isSubmitting} from 'redux-form';
import {
  fetchEnd,
  fetchStart,
  required,
  ReferenceInput,
  showNotification,
  crudGetMatching,
  Button,
  SaveButton,
  SimpleForm,
  SelectInput,
  CREATE,
  GET_LIST,
  REDUX_FORM_NAME
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {hydraClient, fetchHydra as baseFetchHydra} from '@api-platform/admin';
import clubDocumentationParser from "../documentationParser";

const displayCard = (record) => {
  return `Carte #${record.id.replace(/\D+/, '')} - Utilisation : ${record.usageCount}/${record.total} crédits - ${record.secret}`
}

class CardQuickCreateButton extends Component {
  state = {
    error: false,
    showDialog: false,
    api: null
  };

  componentDidMount() {
    clubDocumentationParser(process.env.REACT_APP_API_ENTRYPOINT).then(({api}) => {
      this.setState({api});
    }).catch((e) => {
      console.log(e);
    });
  }

  handleClick = () => {
    this.setState({showDialog: true});
  };

  handleCloseClick = () => {
    this.setState({showDialog: false});
  };

  handleSaveClick = () => {
    const {submit} = this.props;
    submit('card-quick-create');
  };

  handleSubmit = values => {
    const {
      change,
      fetchStart,
      fetchEnd,
      showNotification,
      id,
      record
    } = this.props;

    // Dispatch an action letting react-admin know a API call is ongoing
    fetchStart();

    const fetchHeaders = {
      'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
      'X-HOTEL': process.env.REACT_APP_HOTEL
    };
    const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
      ...options,
      headers: new Headers(fetchHeaders),
    });
    const dataProvider = hydraClient(this.state.api, fetchHydra);

    values.booking = record.id

    dataProvider(CREATE, 'card_usages', {data: values})
      .then(({data}) => {
        dataProvider(GET_LIST, 'card_usages', {
          pagination: {page: 1, perPage: 99999},
          sort: {field: 'id', order: 'DESC'},
          filter: {booking: id}
        }).then(({data}) => {
          change(REDUX_FORM_NAME, 'cards', data.map(card => card['@id']));
          this.setState({showDialog: false});
        })
      })
      .catch(error => {
        showNotification(error.message, 'error');
      })
      .finally(() => {
        fetchEnd();
      });
  };

  render() {
    const {showDialog} = this.state;
    const {isSubmitting, label, record} = this.props;

    return (
      <Fragment>
        <Button onClick={this.handleClick} label={label}>
          <IconContentAdd/>
        </Button>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="Utiliser une carte"
        >
          <DialogTitle>Utiliser une carte</DialogTitle>
          <DialogContent>
            <SimpleForm
              form="card-quick-create"
              resource="card_usages"
              onSubmit={this.handleSubmit}
              toolbar={null}
            >
              <ReferenceInput label="Carte" source="card" reference="cards" filter={{used: false, email: record.email, paid: true}} validate={required()}>
                <SelectInput optionText={displayCard} />
              </ReferenceInput>
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <SaveButton
              saving={isSubmitting}
              onClick={this.handleSaveClick}
            />
            <Button
              label="ra.action.cancel"
              onClick={this.handleCloseClick}
            >
              <IconCancel/>
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting('card-quick-create')(state)
});

const mapDispatchToProps = {
  change,
  crudGetMatching,
  fetchEnd,
  fetchStart,
  showNotification,
  submit
};

export default connect(mapStateToProps, mapDispatchToProps)(
  CardQuickCreateButton
);
