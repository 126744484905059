import React, {Component} from 'react';
import clubDocumentationParser from "../documentationParser";
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import Cancel from '@material-ui/icons/Cancel';
import {showNotification} from 'react-admin';
import {push} from 'react-router-redux';

class BookingCancelButton extends Component {
  state = {
    api: null
  };

  componentDidMount() {
    clubDocumentationParser(process.env.REACT_APP_API_ENTRYPOINT).then(({api}) => {
      this.setState({api});
    }).catch((e) => {
      console.log(e);
    });
  }

  handleClick = (e) => {
    const {push, record, showNotification} = this.props;

    const fetchHeaders = {
      'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
      'X-HOTEL': process.env.REACT_APP_HOTEL
    };
    if (!window.confirm('Voulez-vous vraiment annuler cette réservation ?')) {
      showNotification('Erreur : vous n\'avez pas confirmé l\'annulation', 'warning')
    } else {
      const entrypointUrl = new URL(process.env.REACT_APP_API_ENTRYPOINT);
      const cancel_uri = new URL(`${record.id}/cancel`, entrypointUrl);
      fetch(cancel_uri.toString(), {
        method: 'PATCH',
        headers: new Headers({
          ...fetchHeaders,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        })
      })
        .then(() => {
          showNotification('La réservation est annulée');
          push('/bookings');
        })
        .catch((e) => {
          showNotification('Erreur : la réservation ne peut être annulée', 'warning')
        })
    }
  }

  render() {
    return <Button label="Annuler" color="secondary" onClick={this.handleClick} variant="flat">
      <Cancel></Cancel>
      &nbsp;Annuler
    </Button>;
  }
}

export default connect(null, {
  showNotification,
  push,
})(BookingCancelButton);
