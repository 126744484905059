import React from 'react';
import CardActions from '@material-ui/core/CardActions';
import { Create, Edit, SimpleForm, TextInput, List, Datagrid, TextField, EditButton, ListButton, DeleteButton, RefreshButton } from 'react-admin';

export const UserList = (props) => (
  <List {...props} title="Liste des utilisateurs">
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="email" />
      <EditButton />
      <DeleteButton/>
    </Datagrid>
  </List>
);

const UserTitle = ({ record }) => {
  return <span>{record ? record.name : 'Nouveau utilisateur'}</span>;
};

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

const required = (message = 'Obligatoire') =>
  value => value ? undefined : message;

const UserCreateActions = ({ basePath, data, resource }) => (
  <CardActions style={cardActionStyle}>
    <ListButton basePath={basePath} />
  </CardActions>
);

export const UserCreate = (props) => (
  <Create actions={<UserCreateActions />} title={<UserTitle />} {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <TextInput source="email" type="email" validate={required()} />
      <TextInput source="password" type="password" validate={required()} />
    </SimpleForm>
  </Create>
);

const UserEditActions = ({ basePath, data, resource }) => (
  <CardActions style={cardActionStyle}>
    <ListButton basePath={basePath} />
    <DeleteButton basePath={basePath} record={data} resource={resource} />
    <RefreshButton record={data} />
  </CardActions>
);

export const UserEdit = (props) => (
  <Edit actions={<UserEditActions />} title={<UserTitle />} {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <TextInput source="email" type="email" validate={required()} />
    </SimpleForm>
  </Edit>
);
