import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {change, submit, getFormValues, Field} from 'redux-form';
import {
  fetchEnd,
  fetchStart,
  showNotification,
  crudGetMatching,
  TextInput,
  DateTimeInput,
  GET_ONE,
  REDUX_FORM_NAME
} from 'react-admin';
import LinearProgress from '@material-ui/core/LinearProgress';
import {hydraClient, fetchHydra as baseFetchHydra} from '@api-platform/admin';
import clubDocumentationParser from "../documentationParser";

class BookingHoursInput extends Component {
  _isMounted = false;
  state = {
    error: false,
    room: null,
    api: null,
    isLoading: true
  };

  componentDidMount() {
    this._isMounted = true;
    clubDocumentationParser().then(({api}) => {
      if (this._isMounted) {
        this.setState({api})
        if (this.props.room && this.props.date) {
          this.initRoom()
        }
      }
    }).catch((e) => {
      console.log(e);
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  initRoom = () => {
    const {
      fetchStart,
      fetchEnd,
      showNotification,
      change,
      recordLiveValues,
      formName
    } = this.props;
    const {
      entrypoint
    } = this.state.api;

    if (this._isMounted) {
      let formIndex = null, recordRoom = null, dateStart = '', dateEnd = '';

      if (this.props.index) {
        formIndex = this.props.index;
      }

      // If the room is already in record live values, use its index
      if (formIndex === null && recordLiveValues && recordLiveValues.rooms && !this.props.readonly) {
        recordLiveValues.rooms.forEach((recordLiveRoom, index) => {
          if (recordLiveRoom.room === this.props.room && recordLiveRoom.dateStartPricing && (new Date(recordLiveRoom.dateStartPricing)).toLocaleDateString() === (new Date(recordLiveValues.date)).toLocaleDateString()) {
            formIndex = index;
            dateStart = new Date(recordLiveRoom.dateStartPricing);
            dateEnd = new Date(recordLiveRoom.dateEndPricing);
            recordRoom = recordLiveRoom;
          }
        })
      }
      // Else if main room, reuse index but reset its data
      if (formIndex === null && this.props.main && recordLiveValues && recordLiveValues.rooms) {
        recordLiveValues.rooms.forEach((recordLiveRoom, index) => {
          if (recordLiveRoom.main) {
            formIndex = index;
            recordLiveValues.rooms[index] = {};
          }
        })
      }
      // Else, index is the next in line
      if (formIndex === null) {
        formIndex = recordLiveValues && recordLiveValues.rooms ? recordLiveValues.rooms.length : 0;
        if (recordLiveValues) {
          if (!recordLiveValues.rooms) {
            recordLiveValues.rooms = [];
          }
          recordLiveValues.rooms[formIndex] = {};
        }
      }

      this.setState({
        formIndex,
        dateStart,
        dateEnd,
        recordRoom,
        activeSelection: false
      });
      change(formName ? formName : REDUX_FORM_NAME, `rooms.${formIndex}.room`, this.props.room);
      change(formName ? formName : REDUX_FORM_NAME, `rooms.${formIndex}.main`, this.props.main);
    }

    // Dispatch an action letting react-admin know a API call is ongoing
    fetchStart();
    const fetchHeaders = {
      'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
      'X-HOTEL': process.env.REACT_APP_HOTEL
    };
    const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
      ...options,
      headers: new Headers(fetchHeaders),
    });
    const dataProvider = hydraClient(this.state.api, fetchHydra);

    const date = new Date(this.props.date)
    date.setHours(12); // Set to noon to avoid timezone issues
    const dateTomorrow = new Date(this.props.date)
    dateTomorrow.setHours(12); 
    dateTomorrow.setDate(date.getDate() + 1);

    dataProvider(GET_ONE, 'rooms', {
      id: this.props.room
    })
      .then(response => {
        const room = response.data;
        const entrypointUrl = new URL(entrypoint, window.location.href);
        let params = `?bypass_windows=${this.props.bypassWindows ? 'true' : 'false'}`
        if (recordLiveValues['@id']) {
          params += `&exclude_booking=${recordLiveValues['@id'].replace(/\D+/, '')}`
        }
        const occupation = new URL(`${this.props.room}/occupation/${date.toISOString().slice(0, 10)}${params}`, entrypointUrl);
        const occupationTomorrow = new URL(`${this.props.room}/occupation/${dateTomorrow.toISOString().slice(0, 10)}${params}`, entrypointUrl);

        return Promise.all([
          fetch(occupation.toString())
            .then(response => response.json())
            .then((occupation) => {
              return Promise.resolve({
                room,
                occupation
              })
            }),
          fetch(occupationTomorrow.toString())
            .then(response => response.json())
            .then((occupationTomorrow) => {
              return Promise.resolve({
                occupationTomorrow
              })
            })
        ])
      })
      .then(([{room, occupation}, {occupationTomorrow}]) => {
        occupation = {hours: occupation, day: date}
        occupationTomorrow = {hours: occupationTomorrow, day: dateTomorrow}

        //W-e last hours are friday and saturday
        const nightAvailable = room.nightAvailable;
        const hourOffset = (room.hourOffset + '').padStart(2, '0');

        if (this._isMounted) {
          this.setState({
            room,
            nightAvailable,
            hourOffset,
            occupation,
            occupationTomorrow,
            isLoading: false
          });
        }
      })
      .catch(error => {
        showNotification(error.message, 'error');
      })
      .finally(() => {
        fetchEnd();
      });
  };

  styleHour = {
    width: '75px',
    height: '100%',
    padding: '10px',
    breakInside: 'avoid',
    borderRadius: '3px',
    whiteSpace: 'nowrap',
    fontWeight: '500',
    textAlign: 'center',
    cursor: 'pointer',
    border: '2px solid transparent',
    color: '#fff',
    boxSizing: 'border-box'
  }

  styleDay = {
    display: 'grid',
    gridTemplateRows: 'repeat(12, 1fr)',
    gridAutoFlow: 'column',
    width: '160px',
    gap: '10px'
  }

  stylePlanning = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
    width: '400px',
    margin: '30px 0'
  }

  clickHour = (date) => {
    const {formIndex, occupation, occupationTomorrow, recordRoom} = this.state;
    let {activeSelection, dateStart, dateEnd} = this.state;
    const {change, readonly, formName} = this.props;

    if (readonly) {
      return;
    }

    const bookedHours = [];
    for (let i = 0; i < 24; ++i) {
      if (occupation.hours.indexOf(i) >= 0) {
        const dateTime = new Date(occupation.day.getTime())
        dateTime.setHours(i)
        bookedHours.push(dateTime.getTime())
      }
      if (occupationTomorrow.hours.indexOf(i) >= 0) {
        const dateTime = new Date(occupationTomorrow.day.getTime())
        dateTime.setHours(i)
        bookedHours.push(dateTime.getTime())
      }
    }

    if (!activeSelection && date.toLocaleDateString() !== occupation.day.toLocaleDateString()) {
      return;
    }

    if (bookedHours.indexOf(date.getTime()) >= 0 && (!recordRoom || (activeSelection && recordRoom.dateStartPricing instanceof Date && date.getTime() > recordRoom.dateStartPricing.getTime()) || (recordRoom.dateEndPricing instanceof Date && date.getTime() < recordRoom.dateEndPricing.getTime()))) {
      return;
    }
    if (activeSelection) {
      if (dateStart > date.getTime()) {
        activeSelection = false;
        dateStart = null;
        dateEnd = null;
      } else {
        activeSelection = false;
        dateEnd = new Date(date.getTime());
        // The end is one hour after the selected hour
        dateEnd.setTime(dateEnd.getTime() + 60*60*1000)
        const testDate = new Date(dateStart.getTime())
        while(testDate.getTime() < dateEnd) {
          if (bookedHours.indexOf(testDate.getTime()) >= 0) {
            activeSelection = false;
            dateStart = null;
            dateEnd = null;
          }
          testDate.setTime(testDate.getTime() + 60*60*1000)
        }
      }
    } else {
      activeSelection = true;
      dateStart = date;
      dateEnd = null;
    }

    this.setState({activeSelection, dateStart, dateEnd});
    change(formName ? formName : REDUX_FORM_NAME, `rooms.${formIndex}.dateStartPricing`, dateStart);
    change(formName ? formName : REDUX_FORM_NAME, `rooms.${formIndex}.dateEndPricing`, dateEnd);
    change(formName ? formName : REDUX_FORM_NAME, `rooms.${formIndex}.priceLists`, []);
  }

  pointerInHour = (date) => {
    const {readonly} = this.props;
    const {currentDateHover} = this.state;

    if (currentDateHover && date.toLocaleDateString() === currentDateHover.toLocaleDateString()) {
      return;
    }

    if (readonly) {
      return;
    }

    this.setState({currentDateHover: date});
  }

  pointerOutHour = () => {
    this.setState({currentDateHover: null});
  }

  renderInput({meta: {touched, error}}) {
    const {
      room,
      formIndex,
      isLoading,
      activeSelection,
      currentDateHover,
      dateStart,
      dateEnd,
      nightAvailable,
      hourOffset,
      recordRoom,
      occupation,
      occupationTomorrow
    } = this.state;

    if (!this.props.room) {
      return (
        <div style={{marginTop: 15, color: '#f44336', fontWeight: 500}}>Sélectionnez une chambre avant de pouvoir choisir les heures</div>
      );
    }

    if (!this.props.date) {
      return this.props.readonly ? (
        <div style={{marginTop: 15, color: '#f44336', fontWeight: 500}}>Sélectionnez une date pour consulter les heures</div>
      ) : (
        <div style={{marginTop: 15, color: '#f44336', fontWeight: 500}}>Sélectionnez une date avant de pouvoir choisir les heures</div>
      );
    }

    if (isLoading) {
      return (
        <LinearProgress style={{margin: '20px', width: '200px'}}/>
      );
    }

    const renderDay = (occupation) => {
      let planning = [];

      const dayOfW = occupation.day.getDay();
      let nightEndsAt = [0, 6].indexOf(dayOfW) !== -1 ? room.firstHourWeekend : room.firstHour;
      //W-e last hours are friday and saturday
      let nightStartsAt = [5, 6].indexOf(dayOfW) !== -1 ? room.lastHourWeekend : room.lastHour;

      for (let hour = 0; hour < 24; hour++) {
        let localStyle = {
          ...this.styleHour,
          background: 'grey',
          cursor: this.props.readonly ? 'default' : 'pointer'
        }
        let unavailableHourPast = false
        const dateTime = new Date(occupation.day.getTime())
        dateTime.setHours(hour)
        dateTime.setMinutes(hourOffset)
        if (dateStart instanceof Date && dateTime.getTime() === dateStart.getTime() && activeSelection) {
          localStyle.background = 'orange';
        }
        if (occupation.hours.indexOf(hour) >= 0 && (!recordRoom || !recordRoom.firstHour || hour < recordRoom.firstHour || hour > recordRoom.lastHour)) {
          localStyle.background = 'red';
          localStyle.cursor = 'default';
          unavailableHourPast = true
        }
        if (dateStart instanceof Date && dateEnd instanceof Date && dateTime.getTime() >= dateStart.getTime() && dateTime.getTime() < dateEnd.getTime()) {
          localStyle.background = 'green';
          localStyle.cursor = 'pointer';
          if (occupation.hours.indexOf(hour) >= 0) {
            localStyle.borderColor = 'red';
            unavailableHourPast = true
          }
        }
        if (!unavailableHourPast && currentDateHover && activeSelection && dateStart instanceof Date && dateTime.getTime() > dateStart.getTime() && dateTime.getTime() <= currentDateHover.getTime()) {
          localStyle.background = 'orange';
        }
        const hourDate = new Date(occupation.day.getTime());
        hourDate.setHours(hour)
        hourDate.setMinutes(hourOffset);
        planning.push(
          <div key={hour} data-index={hour} style={localStyle} onClick={() => this.clickHour(hourDate)} onPointerOver={() => this.pointerInHour(hourDate)} onPointerLeave={this.pointerOutHour}>
            {hour}h{hourOffset}
            {nightAvailable && (hour < nightEndsAt || (nightStartsAt > nightEndsAt && hour > nightStartsAt)) && <div>(nuit)</div>}
          </div>
        )
      }
      return <div key={occupation.day.toLocaleDateString()}>
        <h3 style={{textAlign: 'center'}}>
          {occupation.day.toLocaleDateString()}
        </h3>
        <div style={this.styleDay}>
          {planning}
        </div>
      </div>
    }

    let planning = [
      renderDay(occupation),
      //renderDay(occupationTomorrow)
    ];

    return (
      <Fragment>
        <div style={this.stylePlanning}>
          {planning}
        </div>
        <TextInput source={`rooms.${formIndex}.room`} type="hidden" label=""/>
        <DateTimeInput source={`rooms.${formIndex}.dateStartPricing`} type="hidden" label=""/>
        <DateTimeInput source={`rooms.${formIndex}.dateEndPricing`} type="hidden" label=""/>

        <span style={{color: 'red'}}>{touched && error}</span>
      </Fragment>
    );
  }

  render() {
    return <Field name={this.props.source} component={this.renderInput.bind(this)}/>
  }
}

const mapStateToProps = state => ({
  recordLiveValues: getFormValues(REDUX_FORM_NAME)(state)
});

const mapDispatchToProps = {
  change,
  crudGetMatching,
  fetchEnd,
  fetchStart,
  showNotification,
  submit
};

export default connect(mapStateToProps, mapDispatchToProps)(
  BookingHoursInput
);
