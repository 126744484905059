import React, {Component} from 'react';
import {Admin, Resource} from 'react-admin';
import {hydraClient, fetchHydra as baseFetchHydra} from '@api-platform/admin';
import clubDocumentationParser from './documentationParser';
import authProvider from './authProvider';
import Layout from './Components/Layout';
import CustomLoginPage from "./Components/LoginPage";
import {createMuiTheme} from '@material-ui/core/styles';
import {
  People as UserIcon,
  Room as RoomIcon,
  Settings as SettingsIcon,
  TrendingUp as TrendingUpIcon,
  MonetizationOn as MonetizationOnIcon,
  Event as EventIcon,
  CardGiftcard
} from '@material-ui/icons';

import frenchMessages from 'ra-language-french';
import * as domainMessages from './i18n';

import {UserList, UserCreate, UserEdit} from './Components/Users';
import {OptionList, OptionShow, OptionCreate, OptionEdit} from './Components/Options';
import {BookingList, BookingCreate, BookingEdit} from './Components/Bookings';
import {OptionValueCreate, OptionValueEdit} from './Components/OptionValues';
import {RoomList, RoomShow, RoomCreate, RoomEdit} from './Components/Rooms';
import {DiscountList, DiscountCreate, DiscountEdit} from './Components/Discounts';
import {PriceListList, PriceListCreate, PriceListEdit} from './Components/PriceLists';
import {CardList, CardCreate, CardEdit, CardShow} from './Components/Cards';
import {ReportingList} from './Components/Reporting';
import {StatsList} from './Components/Stats';
import {PaymentList, PaymentCreate, PaymentEdit} from './Components/Payments';

const theme = createMuiTheme({
  palette: {
    type: 'light'
  },
});

const messages = {
  fr: {...frenchMessages, ...domainMessages.fr}
};
const i18nProvider = locale => messages[locale];
const fetchHeaders = {
  'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
  'X-HOTEL': process.env.REACT_APP_HOTEL
};
const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
  ...options,
  headers: new Headers(fetchHeaders),
});
const dataProvider = api => hydraClient(api, fetchHydra);

export default class extends Component {
  state = {api: null};

  componentDidMount() {
    clubDocumentationParser().then(({api}) => {
      this.setState({api});
    }).catch((e) => {
      console.log(e);
    });
  }

  render() {
    if (null === this.state.api) return <div>Loading...</div>;
    return (
      <Admin api={this.state.api}
             apiDocumentationParser={clubDocumentationParser}
             dataProvider={dataProvider(this.state.api)}
             theme={theme}
             appLayout={Layout}
             authProvider={authProvider}
             loginPage={CustomLoginPage}
             locale="fr"
             title="Lovehotel"
             i18nProvider={i18nProvider}
      >
        <Resource name="bookings" list={BookingList} create={BookingCreate} edit={BookingEdit}
                  title="Réservations"
                  icon={EventIcon}/>
        <Resource name="rooms" list={RoomList} show={RoomShow} create={RoomCreate} edit={RoomEdit} title="Chambres"
                  icon={RoomIcon}/>
        <Resource name="price_lists" list={PriceListList} create={PriceListCreate} edit={PriceListEdit} title="Grilles tarifaires" icon={MonetizationOnIcon}/>
        <Resource name="cards" list={CardList} create={CardCreate} edit={CardEdit} show={CardShow} title="Cartes" icon={CardGiftcard}/>
        <Resource name="card_usages"/>
        <Resource name="discounts" list={DiscountList} create={DiscountCreate} edit={DiscountEdit} title="Promos" icon={MonetizationOnIcon}/>
        <Resource name="options" list={OptionList} show={OptionShow} create={OptionCreate} edit={OptionEdit}
                  title="Options" icon={SettingsIcon}/>
        <Resource name="users" list={UserList} create={UserCreate} edit={UserEdit} title="Utilisateurs"
                  icon={UserIcon}/>
        <Resource name="option_values" create={OptionValueCreate} edit={OptionValueEdit}/>
        <Resource name="room_blockings"/>
        <Resource name="room_windows"/>
        <Resource name="payments" list={PaymentList} create={PaymentCreate} edit={PaymentEdit}/>
        <Resource name="reporting" list={ReportingList} options={{label: "Tableau récap"}}/>
        <Resource name="stats" list={StatsList} title="Statistiques" icon={TrendingUpIcon}/>
      </Admin>
    )
  }
}
