import React from 'react';
import {Redirect} from 'react-router-dom';
import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';

const fetchHeaders = {
  'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
  'X-HOTEL': process.env.REACT_APP_HOTEL
};

let documentation = null;

const api = (entrypoint) => parseHydraDocumentation(entrypoint, {headers: new Headers(fetchHeaders)})
  .then(({api}) => {
      api.resources.map(resource => {
        resource.fields.map(field => {
          if ('image' === field.name) {
            field.denormalizeData = value => ({
              original: value,
              src: value.contentUrl ? `${entrypoint}${value.contentUrl}` : null
            })

            field.normalizeData = value => {
              if (value && value.rawFile instanceof File) {
                const body = new FormData();
                body.append('file', value.rawFile);

                return fetch(`${entrypoint}/media_objects/upload`, {body, method: 'POST', headers: fetchHeaders})
                  .then(response => response.json())
                  .then(value => `/media_objects/${value.id}`)
              }
              if (value) {
                return value.original ? value.original : value.src;
              } else {
                return null;
              }
            };
          } else {
            field.normalizeData = value => {
              if (value === '') {
                return null;
              }
              return value;
            }
          }
          return field;
        });
        return resource;
      });
      return {api};
    },
    (result) => {
      switch (result.status) {
        case 401:
          return Promise.resolve({
            api: result.api,
            customRoutes: [{
              props: {
                path: '/',
                render: () => <Redirect to={`/login`}/>,
              },
            }],
          });

        default:
          return Promise.reject(result);
      }
    })
;

export default () => {
  if (!documentation) {
    documentation = api(process.env.REACT_APP_API_ENTRYPOINT);
  }
  return documentation;
}
