import React from 'react';
import CardActions from '@material-ui/core/CardActions';
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceArrayInput,
  SelectArrayInput,
  CheckboxGroupInput,
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  ReferenceArrayField,
  ChipField,
  SingleFieldList,
  EditButton,
  ListButton,
  DeleteButton,
  RefreshButton,
  DateInput,
} from 'react-admin';

const MyChip = ({record, source, ...props}) => (record && (record[source] !== undefined) ?
  <ChipField {...{record, source, ...props}}/> : null)

const days = [
  {id: 1, name: 'Lundi'},
  {id: 2, name: 'Mardi'},
  {id: 3, name: 'Mercredi'},
  {id: 4, name: 'Jeudi'},
  {id: 5, name: 'Vendredi'},
  {id: 6, name: 'Samedi'},
  {id: 0, name: 'Dimanche'},
]

const DaysField = ({source, record = {}}) =>
  <span>{record[source].map(record => days.filter(day => day.id === record)[0].name).join(', ')}</span>;

export const DiscountList = (props) => (
  <List {...props} title="Liste des promos">
    <Datagrid rowClick="show">
      <TextField source="name"/>
      <NumberField source="priceHour" options={{style: 'currency', currency: 'EUR'}}/>
      <NumberField source="priceNight" options={{style: 'currency', currency: 'EUR'}}/>
      <NumberField source="firstHour"/>
      <NumberField source="lastHour"/>
      <DateField source="dateStart"/>
      <DateField source="dateEnd"/>
      <DaysField source="days"/>
      <ReferenceArrayField source="rooms" reference="rooms" {...props}>
        <SingleFieldList>
          <MyChip source="name" key="name"/>
        </SingleFieldList>
      </ReferenceArrayField>
      <EditButton/>
    </Datagrid>
  </List>
);

const DiscountTitle = ({record}) => {
  return <span>{record ? record.name : 'Nouvelle promo'}</span>;
};

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

const required = (message = 'Obligatoire') =>
  value => value ? undefined : message;

const DiscountCreateActions = ({basePath, data, resource}) => (
  <CardActions style={cardActionStyle}>
    <ListButton basePath={basePath}/>
  </CardActions>
);

const form = (props) => (
  <SimpleForm>
    <TextInput source="name" validate={required()}/>
    <TextInput source="code" helperText="Code à saisir pour appliquer la promo. Si aucun code, la promo sera appliquée automatiquement"/>
    <NumberInput source="priceHour"/>
    <NumberInput source="priceNight"/>
    <NumberInput source="firstHour"/>
    <NumberInput source="lastHour"/>
    <DateInput source="dateStart"/>
    <DateInput source="dateEnd"/>
    <CheckboxGroupInput source="days" choices={days}/>
    <ReferenceArrayInput label="Chambres concernées" source="rooms" reference="rooms">
      <SelectArrayInput optionText="name"/>
    </ReferenceArrayInput>
    <p>En laissant vide, toutes les chambres seront concernées</p>
  </SimpleForm>
)

export const DiscountCreate = (props) => (
  <Create actions={<DiscountCreateActions/>} title={<DiscountTitle/>} {...props}>
    {form(props)}
  </Create>
);

const DiscountEditActions = ({basePath, data, resource}) => (
  <CardActions style={cardActionStyle}>
    <ListButton basePath={basePath}/>
    <DeleteButton basePath={basePath} record={data} resource={resource}/>
    <RefreshButton record={data}/>
  </CardActions>
);

export const DiscountEdit = (props) => (
  <Edit actions={<DiscountEditActions/>} title={<DiscountTitle/>} {...props}>
    {form(props)}
  </Edit>
);
