import React from 'react';
import CardActions from '@material-ui/core/CardActions';
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
  ReferenceArrayInput,
  SelectArrayInput,
  CheckboxGroupInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  BooleanField,
  EditButton,
  ListButton,
  DeleteButton,
  RefreshButton,
  DateInput,
} from 'react-admin';

const days = [
  {id: 1, name: 'Lundi'},
  {id: 2, name: 'Mardi'},
  {id: 3, name: 'Mercredi'},
  {id: 4, name: 'Jeudi'},
  {id: 5, name: 'Vendredi'},
  {id: 6, name: 'Samedi'},
  {id: 0, name: 'Dimanche'},
]

export const PriceListList = (props) => (
  <List {...props} filter={{ archived: false }} title="Liste des grilles tarifaires">
    <Datagrid rowClick="show">
      <TextField source="name"/>
      <TextField source="publicName"/>
      <NumberField source="priority"/>
      <NumberField source="priceHour"/>
      <NumberField source="priceNight"/>
      <NumberField source="hourStart"/>
      <NumberField source="hourEnd"/>
      <DateField source="dateStart"/>
      <DateField source="dateEnd"/>
      <BooleanField source="active"/>
      <EditButton/>
    </Datagrid>
  </List>
);

const PriceListTitle = ({record}) => {
  return <span>{record ? record.name : 'Nouvelle grille tarifaire'}</span>;
};

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

const required = (message = 'Obligatoire') =>
  value => value ? undefined : message;

const PriceListCreateActions = ({basePath, data, resource}) => (
  <CardActions style={cardActionStyle}>
    <ListButton basePath={basePath}/>
  </CardActions>
);

const form = (props) => (
  <SimpleForm>
    <TextInput source="name" validate={required()}/>
    <TextInput source="publicName" validate={required()}/>
    <BooleanInput source="active"/>
    <DateInput source="dateStart" validate={required()}/>
    <DateInput source="dateEnd" validate={required()}/>
    <NumberInput source="priority" validate={required()}/>
    <NumberInput source="priceHour"/>
    <NumberInput source="priceNight"/>
    <NumberInput source="hourStart"/>
    <NumberInput source="hourEnd"/>
    <CheckboxGroupInput source="days" choices={days} validate={required()}/>
    <ReferenceArrayInput label="Chambres concernées" source="rooms" reference="rooms">
      <SelectArrayInput optionText="name"/>
    </ReferenceArrayInput>
    <p>En laissant vide, toutes les chambres seront concernées</p>
    <ArrayInput source="degressivity">
      <SimpleFormIterator getItemLabel={(index) => `${index + 1}ème heure`}>
        <NumberInput source="price" label="Prix" />
        <SelectInput source="type" label="Type" choices={[
          { id: 'percent', name: '%' },
          { id: 'amount', name: '€' },
        ]} />
      </SimpleFormIterator>
    </ArrayInput>
  </SimpleForm>
)

export const PriceListCreate = (props) => (
  <Create actions={<PriceListCreateActions/>} title={<PriceListTitle/>} {...props}>
    {form(props)}
  </Create>
);

const PriceListEditActions = ({basePath, data, resource}) => (
  <CardActions style={cardActionStyle}>
    <ListButton basePath={basePath}/>
    <DeleteButton basePath={basePath} record={data} resource={resource}/>
    <RefreshButton record={data}/>
  </CardActions>
);

export const PriceListEdit = (props) => (
  <Edit actions={<PriceListEditActions/>} title={<PriceListTitle/>} {...props}>
    {form(props)}
  </Edit>
);
