import React from 'react';
import CardActions from '@material-ui/core/CardActions';
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  LongTextInput,
  NumberInput,
  SelectInput,
  BooleanInput,
  Show,
  List,
  TabbedShowLayout,
  Tab,
  Datagrid,
  DateField,
  TextField,
  BooleanField,
  NumberField,
  FunctionField,
  ReferenceManyField,
  ReferenceArrayField,
  ReferenceField,
  ShowButton,
  EditButton,
  ListButton,
  DeleteButton,
  RefreshButton,
  Filter
} from 'react-admin';
import PaymentReferenceInput from "./PaymentReferenceInput";

const CardFilters = (props) => (
  <Filter {...props}>
    <TextInput label="Code secret" source="secret" alwaysOn />
    <TextInput label="Email" source="email" alwaysOn />
    <BooleanInput label="Utilisée" source="used" alwaysOn defaultValue={false} />
  </Filter>
);

export const CardList = (props) => (
  <List {...props} filter={{ temp: false }} title="Liste des cartes de fidélité" filters={<CardFilters />} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <TextField source="id"/>
      <TextField source="email"/>
      <TextField source="secret"/>
      <DateField source="created"/>
      <NumberField source="total"/>
      <NumberField source="usageCount"/>
      <BooleanField source="used"/>
      <BooleanField source="paid"/>
      <TextField source="source"/>
      <ShowButton/>
      <EditButton/>
    </Datagrid>
  </List>
);

const CardTitle = ({record}) => {
  return <span>{record.id ? `Carte #${record.id.replace(/^\D+/, '')}` : 'Nouvelle carte'}</span>;
};

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

const CardShowActions = ({basePath, data, resource}) => (
  <CardActions style={cardActionStyle}>
    <EditButton basePath={basePath} record={data}/>
    <ListButton basePath={basePath}/>
    <DeleteButton basePath={basePath} record={data} resource={resource}/>
    <RefreshButton record={data}/>
  </CardActions>
);

const formatPayment = record => {
  const date = record.date instanceof Date ? record.date : new Date(record.date);
  return `${record.mode} | ${date.toLocaleDateString()} - ${record.amount}€`;
}

export const CardShow = (props) => {
  return (
    <Show title={<CardTitle/>} actions={<CardShowActions/>} {...props}>
      <TabbedShowLayout>
        <Tab label="La carte">
          <DateField source="created"/>
          <TextField source="secret"/>
          <TextField source="email" type="email"/>
          <TextField source="phone"/>
          <NumberField source="total"/>
          <NumberField source="usageCount"/>
          <BooleanField source="used"/>
          <BooleanField source="paid"/>
          <ReferenceArrayField label="" source="payments" reference="payments" {...props}>
            <Datagrid>
              <FunctionField label="Paiements" render={formatPayment} />
            </Datagrid>
          </ReferenceArrayField>
          <TextField source="source"/>
          <TextField source="comment"/>
        </Tab>
        <Tab label="Les réservations">
          <ReferenceManyField
            addLabel={false}
            reference="card_usages"
            target="card"
            filter={{ temp: false }}
          >
            <Datagrid>
              <DateField label="Date de l'utilisation" source="created" showTime/>
              <ReferenceField label="Date de la réservation" source="booking" reference="bookings" {...props}>
                <DateField source="date" key="date"/>
              </ReferenceField>
              <ReferenceField label="Nom de la réservation" source="booking" reference="bookings" {...props}>
                <TextField source="name" key="name"/>
              </ReferenceField>
              <NumberField label="Crédits utilisés" source="credits"/>
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

const required = (message = 'Obligatoire') =>
  value => value ? undefined : message;

const CardCreateActions = ({basePath, data, resource}) => (
  <CardActions style={cardActionStyle}>
    <ListButton basePath={basePath}/>
  </CardActions>
);

export const CardCreate = (props) => (
  <Create actions={<CardCreateActions/>} title={<CardTitle/>} {...props}>
    <SimpleForm redirect="edit">
      <TextInput source="email" type="email" validate={required()}/>
      <BooleanInput source="confirmationMail"/>
      <TextInput source="phone"/>
      <TextInput source="secret"/>
      <NumberInput source="total"/>
      <NumberInput source="amount"/>
      <SelectInput source="source" choices={[
        {id: 'Sur place', name: 'Sur place'},
        {id: 'Chat', name: 'Chat'},
        {id: 'Direct', name: 'Direct'},
        {id: 'Web', name: 'Web'}
      ]} validate={required()}/>
      <LongTextInput source="comment"/>
    </SimpleForm>
  </Create>
);

const CardEditActions = ({basePath, data, resource}) => (
  <CardActions style={cardActionStyle}>
    <ShowButton basePath={basePath} record={data}/>
    <ListButton basePath={basePath}/>
    <DeleteButton basePath={basePath} record={data} resource={resource}/>
  </CardActions>
);

export const CardEdit = (props) => (
  <Edit actions={<CardEditActions/>} title={<CardTitle/>} {...props}>
    <SimpleForm>
      <DateField source="created"/>
      <TextField source="secret"/>
      <TextInput source="email" type="email" validate={required()}/>
      <BooleanInput source="confirmationMail"/>
      <TextInput source="phone"/>
      <TextInput source="secret"/>
      <NumberInput source="total"/>
      <NumberField source="usage"/>
      <NumberInput source="amount"/>
      <PaymentReferenceInput source="payments" label="Paiement" allowEmpty />
      <SelectInput source="source" choices={[
        {id: 'Sur place', name: 'Sur place'},
        {id: 'Chat', name: 'Chat'},
        {id: 'Direct', name: 'Direct'},
        {id: 'Web', name: 'Web'}
      ]} validate={required()}/>
      <LongTextInput source="comment"/>
    </SimpleForm>
  </Edit>
);
