const bookingFields = {
  name: 'Nom',
  room: 'Chambre',
  fidelity: 'Fidélité',
  created: 'Date d\'enregistrement',
  phone: 'Téléphone',
  came: 'Est venu',
  confirmationMail: 'Confirmation par mail ?',
  amountManual: 'Montant personnalisé',
  amount: 'Montant',
  hours: 'Heures',
  paid: 'Payée',
  promo: 'Code promo',
  card: 'Carte pré-payée',
  rooms: 'Chambre(s)',
  secret: 'Code secret',
  comment: 'Commentaire',
  payments: 'Paiements',
  cancelled: 'Annulée'
}

module.exports = {
  fr: {
    'Not Found': 'Non trouvé',
    'Unauthorized': 'Identifiants invalides',
    "rooms: Superposition" : "Les chambres ou options avec planning se superposent à d'autres réservations",
    "card: Exceeded": 'Avec ce paiement, le montant de la carte serait dépassé',
    resources: {
      users: {
        name: 'Utilisateur |||| Utilisateurs',
        fields: {
          name: 'Nom',
        },
      },
      options: {
        name: 'Option |||| Options',
        fields: {
          name: 'Nom',
          multipleChoices: 'Choix multiple',
          discount: 'Réduction',
          room: 'Chambre',
          isActive: 'Active'
        },
      },
      option_values: {
        name: 'Valeur |||| Valeurs',
        fields: {
          name: 'Nom',
          price: 'Prix',
          discount: 'Réduction',
          rooms: 'Chambres concernées',
          addHour: 'Ajoute des heures à la réservation',
          addHourNightGlued: 'Heure(s) ajoutée(s) seulement sur la nuit',
          addHourBefore: 'Heure(s) ajoutée(s) avant et non après la réservation',
          isActive: 'Active'
        },
      },
      rooms: {
        name: 'Chambre |||| Chambres',
        fields: {
          name: 'Nom',
          image: 'Illustration',
          priceHour: 'Prix par heure',
          priceNight: 'Prix par nuit',
          firstHour: 'Première heure dans la semaine',
          firstHourWeekend: 'Première heure le week-end',
          lastHour: 'Dernière heure dans la semaine',
          lastHourWeekend: 'Dernière heure le week-end',
          hourOffset: 'Décallage en minutes',
          nightAvailable: 'Nuit disponible',
          isOption: 'Lié à une option',
          isActive: 'Active',
          isRestricted: 'Restreinte à des plages horaires'
        },
      },
      price_lists: {
        name: 'Grille tarifaire |||| Grille tarifaires',
        fields: {
          name: 'Nom',
          publicName: 'Nom public',
          priority: 'Priorité',
          priceHour: 'Prix par heure',
          priceNight: 'Prix de la nuit',
          hourStart: 'Première heure',
          hourEnd: 'Dernière heure',
          days: 'Jours applicables',
          rooms: 'Chambres concernées',
          dateStart: 'Date de début',
          dateEnd: 'Date de fin',
          degressivity: 'Dégressivité'
        },
      },
      discounts: {
        name: 'Promo |||| Promos',
        fields: {
          name: 'Nom',
          priceHour: 'Prix par heure',
          priceNight: 'Prix de la nuit',
          firstHour: 'Première heure',
          lastHour: 'Dernière heure',
          hourOffset: 'Décallage en minutes',
          days: 'Jours applicables',
          rooms: 'Chambres concernées',
          dateStart: 'Date de début',
          dateEnd: 'Date de fin'
        },
      },
      room_blockings: {
        name: 'Bloquage |||| Bloquages',
        fields: {
          reason: 'Raison',
          firstHour: 'Première heure',
          lastHour: 'Dernière heure',
          night: 'Nuit',
          dateEnd: 'Date de fin'
        },
      },
      room_windows: {
        name: 'Autorisation |||| Autorisations',
        fields: {
          firstHour: 'Première heure',
          lastHour: 'Dernière heure',
          night: 'Nuit',
          dateEnd: 'Date de fin'
        },
      },
      cards: {
        name: 'Carte de fidélité |||| Cartes de fidélité',
        fields: {
          name: 'Nom',
          created: 'Date d\'enregistrement',
          expiry: 'Date d\'expiration',
          phone: 'Téléphone',
          total: 'Nombre de crédits total',
          used: 'Utilisée',
          paid: 'Payé',
          amount: 'Montant',
          usageCount: 'Nombre de crédits utilisés',
          secret: 'Code secret',
          comment: 'Commentaire',
          payment: 'Paiement'
        },
      },
      payments: {
        name: 'Paiement |||| Paiements',
        fields: {
          name: 'Nom',
          amount: 'Montant',
          booking: 'Réservation',
          card: 'Carte'
        },
      },
      bookings: {
        name: 'Réservation |||| Réservations',
        fields: bookingFields,
      },
      reporting: {
        fields: bookingFields,
      },
    }
  }
}
